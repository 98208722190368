import { Action, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { ITeam, ITeamState } from "../../interfaces/team/team";
import { IProject, IProjectState } from "../../interfaces/projects/projects";
import { getTeamApi, getTeamsApi } from "../../api/team/team";

const initialState: ITeamState = {
  teams: {
    entity: [],
    isVisible: false,
    loaded: false,
    errors: {
      isError: false,
      message: ""
    }
  },
  team: {
    entity: {} as ITeam,
    loaded: false,
    errors: {
      isError: false,
      message: ""
    }
  }
};

export const teamSelector = (state: { teams: ITeamState }) => state.teams;

const teamSlice = createSlice({
  name: "Teams",
  initialState: initialState,
  reducers: {
    getTeams: (state, action: PayloadAction<ITeam[] | undefined>) => {
      state.teams.entity = action.payload ? action.payload : [];
      state.teams.loaded = true;
      state.teams.errors.isError = false;
    },
    teamsError: (state, action: PayloadAction<string>) => {
      state.teams.loaded = true;
      state.teams.errors.isError = true;
      state.teams.errors.message = action.payload;
    },
    getTeam: (state, action: PayloadAction<ITeam | undefined>) => {
      state.team.entity = action.payload ? action.payload : ({} as ITeam);
      state.team.loaded = true;
      state.team.errors.isError = false;
    },
    teamError: (state, action: PayloadAction<string>) => {
      state.team.loaded = true;
      state.team.errors.isError = true;
      state.team.errors.message = action.payload;
    },
    createTeam: (state, action: PayloadAction<ITeam>) => {
      state.teams.loaded = true;
      state.teams.entity = [...state.teams.entity, action.payload];
      state.teams.errors.isError = false;
    },
    updateTeam: (state, action: PayloadAction<ITeam>) => {
      state.teams.loaded = true;
      state.teams.entity = state.teams.entity.filter(team => {
        if (team.id === action.payload.id) {
          return action.payload;
        }
        return team;
      });
      state.teams.errors.isError = false;
    },
    deleteTeam: (state, action: PayloadAction<IProject>) => {
      state.teams.loaded = true;
      state.teams.entity = state.teams.entity.filter(team => {
        if (team.id === action.payload.id) {
          return false;
        }
        return team;
      });
    },
    setTeamLoaded: (state, action: PayloadAction<boolean>) => {
      state.team.loaded = action.payload;
    },
    setVisible: (state, action: PayloadAction<boolean>) => {
      state.teams.isVisible = action.payload;
    }
  }
});

export const {
  createTeam,
  deleteTeam,
  getTeam,
  getTeams,
  teamError,
  teamsError,
  updateTeam,
  setTeamLoaded,
  setVisible
} = teamSlice.actions;

// Async Thunk
export const getTeamsThunk = (data?: string): ThunkAction<void, ITeamState, unknown, Action<string>> => {
  return async dispatch => {
    try {
      const teams: ITeam[] | undefined = await getTeamsApi();
      console.log(teams);

      dispatch(getTeams(teams));
    } catch (e: any) {
      console.log(e);

      if (e.response && e.response.data) {
        dispatch(teamsError(e.response.data.message));
      }
    }
  };
};
export const getTeamThunk = (id: string, redirect?: any): ThunkAction<void, IProjectState, unknown, Action<string>> => {
  return async dispatch => {
    try {
      dispatch(setTeamLoaded(false));
      const team: ITeam | undefined = await getTeamApi(id);
      dispatch(getTeam(team));
      dispatch(setTeamLoaded(true));
    } catch (e: any) {
      dispatch(setTeamLoaded(true));
      if (e.response && e.response.status === 403) {
        if (redirect) {
          redirect("/403");
        }
      }
      if (e.response && e.response.status === 404) {
        if (redirect) {
          redirect("/404");
        }
      }
      if (e.response && e.response.data) {
        dispatch(teamError(e.response.data.message));
      }
    }
  };
};

export const createTeamThunk = (name: string): ThunkAction<void, IProjectState, unknown, Action<string>> => {
  return async dispatch => {
    try {
    } catch (e) {}
  };
};

export default teamSlice.reducer;
