import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../../../../hooks/useDebounce";
import {
  getProject,
  getProjects,
  getProjectsThunk,
  projectsSelector
} from "../../../../../../store/slices/projects-slice";
import { getProjectsApi } from "../../../../../../api/project/project";
import Loader from "../../../../../../shared/loader/loader";
import { filterSelector, setFilter, setPage } from "../../../../../../store/slices/project-filter-slice";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface props {}

const ProjectSearchInput: React.FC<props> = ({}) => {
  const { projectOptions } = useSelector(filterSelector);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { teamId } = useParams();
  const { t } = useTranslation();
  const debouncedText = useDebounce(projectOptions.filter.filterValue, 500);
  const dispatch = useDispatch();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const onChange = async (e: any) => {
    dispatch(setPage(1));
    dispatch(setFilter({ filterBy: "name", filterValue: e.target.value }));
    setIsLoad(true);
  };
  const focusHandler = (e: any) => setIsFocused(true);
  const unFocusHandler = () => setIsFocused(false);

  useEffect(() => {
    if (isLoad) {
      if (debouncedText) {
        setConfirmLoading(true);
        getProjectsApi(
          "?page=1",
          "",
          "",
          `&filterBy=name`,
          `&filterValue=${debouncedText}`,
          teamId ? `&teamID=${teamId}` : ""
        )
          .then(response => {
            if (response) {
              dispatch(getProjects(response));
            }
          })
          .catch(e => {
            if (e.response.status === 500 || e.response.status === 0) {
              navigate("/500");
            }
          })
          .finally(() => setConfirmLoading(false));
      } else {
        dispatch(getProjectsThunk(1, teamId));
      }
    }
    return () => {
      setIsLoad(false);
    };
  }, [debouncedText]);
  return (
    <div className={"analysis-wrapper__item filter__input"}>
      <input
        onFocus={focusHandler}
        onBlur={unFocusHandler}
        type={"text"}
        className={"analysis-wrapper__search"}
        value={projectOptions.filter.filterBy === "name" ? projectOptions.filter.filterValue : undefined}
        onChange={onChange}
        placeholder={t("findProjects")}
      />
      <svg
        className={`analysis-wrapper__search--icon ${projectOptions.filter.filterValue.trim().length === 0 ? "" : "hidden"}`}
        width='16'
        height='17'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0.703125 6.75781C0.703125 7.5599 0.854167 8.3125 1.15625 9.01562C1.45833 9.71875 1.875 10.3385 2.40625 10.875C2.94271 11.4115 3.5625 11.8307 4.26562 12.1328C4.96875 12.4349 5.72135 12.5859 6.52344 12.5859C7.17448 12.5859 7.78906 12.487 8.36719 12.2891C8.94531 12.0859 9.47656 11.8073 9.96094 11.4531L13.6797 15.1875C13.7578 15.2604 13.8464 15.3151 13.9453 15.3516C14.0495 15.3932 14.1536 15.4141 14.2578 15.4141C14.4141 15.4141 14.5521 15.3776 14.6719 15.3047C14.7917 15.237 14.8854 15.1406 14.9531 15.0156C15.0208 14.8958 15.0547 14.7578 15.0547 14.6016C15.0547 14.4974 15.0339 14.3958 14.9922 14.2969C14.9557 14.2031 14.901 14.1172 14.8281 14.0391L11.125 10.3203C11.5104 9.83073 11.8125 9.28385 12.0312 8.67969C12.25 8.07031 12.3594 7.42969 12.3594 6.75781C12.3594 5.95052 12.2083 5.19531 11.9062 4.49219C11.6042 3.78906 11.1849 3.17188 10.6484 2.64062C10.112 2.10417 9.49219 1.6849 8.78906 1.38281C8.08594 1.07552 7.33073 0.921875 6.52344 0.921875C5.72135 0.921875 4.96875 1.07552 4.26562 1.38281C3.5625 1.6849 2.94271 2.10417 2.40625 2.64062C1.875 3.17188 1.45833 3.78906 1.15625 4.49219C0.854167 5.19531 0.703125 5.95052 0.703125 6.75781ZM1.83594 6.75781C1.83594 6.10677 1.95833 5.5 2.20312 4.9375C2.44792 4.36979 2.78385 3.8724 3.21094 3.44531C3.64323 3.01302 4.14062 2.67708 4.70312 2.4375C5.27083 2.19271 5.8776 2.07031 6.52344 2.07031C7.17448 2.07031 7.78125 2.19271 8.34375 2.4375C8.91146 2.67708 9.40885 3.01302 9.83594 3.44531C10.2682 3.8724 10.6042 4.36979 10.8438 4.9375C11.0885 5.5 11.2109 6.10677 11.2109 6.75781C11.2109 7.40365 11.0885 8.01042 10.8438 8.57812C10.6042 9.14062 10.2682 9.63802 9.83594 10.0703C9.40885 10.4974 8.91146 10.8333 8.34375 11.0781C7.78125 11.3177 7.17448 11.4375 6.52344 11.4375C5.8776 11.4375 5.27083 11.3177 4.70312 11.0781C4.14062 10.8333 3.64323 10.4974 3.21094 10.0703C2.78385 9.63802 2.44792 9.14062 2.20312 8.57812C1.95833 8.01042 1.83594 7.40365 1.83594 6.75781Z'
          fill={`${isFocused ? "#FFFFFF" : "#000000"}`}
        />
      </svg>
    </div>
  );
};

export default ProjectSearchInput;
