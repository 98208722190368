import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCompareToId, uploadSelector } from "../../../../../../store/slices/upload-project-slice";
import { IDataSelects } from "../../upload-form";
import { useParams } from "react-router-dom";
import { IProject } from "../../../../../../interfaces/projects/projects";
import { getProjectsApi, getProjectsToCompare } from "../../../../../../api/project/project";
import { SpinnerCircular } from "spinners-react";
import { useTranslation } from "react-i18next";

interface props {
  isVisible: boolean;
  visibleHandler: (data: IDataSelects) => void;
}

const CustomProjectsSelect: React.FC<props> = ({ isVisible, visibleHandler }) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { teamId } = useParams();
  const { upload } = useSelector(uploadSelector);
  const [page, setPage] = useState(1);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const listRef = useRef<HTMLUListElement | null>(null);
  const [loading, setLoading] = useState(false);
  const hideOptions = (e: any) => {
    if (wrapperRef.current) {
      // @ts-ignore
      if (!wrapperRef.current.contains(e.target)) {
        setIsOptionsOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", hideOptions);
    return () => document.removeEventListener("mouseup", hideOptions);
  }, []);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const setAllDatabase = (id: number) => {
    dispatch(setCompareToId(id));
    setIsOptionsOpen(false);
  };

  // Функция для загрузки проектов
  const loadProjects = (page: number) => {
    getProjectsToCompare(`?page=${page}`, "", "", "", "", teamId ? `&teamid=${teamId}` : "", "&count=11").then(res => {
      if (res.items.length > 0) {
        setTotalCount(res.total_count);
        setProjects(prevProjects => [...prevProjects, ...res.items]);
      } else {
        setHasMore(false); // Если новых проектов нет, прекращаем загрузку
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if ((projects.length < totalCount || page < totalPages) && !loading) {
      setLoading(true);
      loadProjects(page);
    }
  }, [page]);
  useEffect(() => {
    getProjectsToCompare(`?page=1`, "", "", "", "", teamId ? `&teamid=${teamId}` : "", "&count=11").then(res => {
      if (res.items.length > 0) {
        setTotalCount(res.total_count);
        setTotalPages(res.pages_count);
        setProjects(res.items);
      }
    });
  }, []);
  // Функция для проверки, достигнут ли конец списка
  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore) {
        if (page < totalPages && !loading) {
          setPage(prevPage => prevPage + 1);
        }
      }
    }
  };

  return (
    <div ref={wrapperRef} className='wrapper'>
      <div className='custom-select__container'>
        <button type='button' onClick={toggleOptions} className={"custom-select__file"}>
          <div>{upload.compare_to_id ? upload.compare_to_id : t("compareProject")}</div>
          <div className={`arrow ${isOptionsOpen ? "arrow-active" : ""}`}></div>
        </button>
        {isOptionsOpen && (
          <ul
            ref={listRef}
            className={"custom-select__list"}
            style={{ maxHeight: "400px", overflowY: "auto" }}
            onScroll={handleScroll}
          >
            {projects.map(({ id, name }) => (
              <li onClick={() => setAllDatabase(id)} className={"custom-select__import"} key={id}>
                {name}
              </li>
            ))}
            {loading && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <SpinnerCircular />
              </div>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CustomProjectsSelect;
