import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import team from "../../../assets/help/team.png";
import team2 from "../../../assets/help/team2.png";
import team3 from "../../../assets/help/team3.png";
import settings from "../../../assets/help/settings.png";
import settings1 from "../../../assets/help/settings1.png";
import settings2 from "../../../assets/help/settings2.png";
import settings3 from "../../../assets/help/settings3.png";

interface props {}

const Teams: React.FC<props> = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const startGuide = [
    {
      title: t("team.workWith.title"),
      img: team3,
      text: t("team.workWith.text")
    },
    {
      title: t("team.members.title"),
      img: team2,
      text: t("team.members.text")
    }
  ];

  const settingsGuide = [
    {
      img: [settings],
      text: t("team.settings.first")
    },
    {
      img: [settings1, settings2],
      text: t("team.settings.second"),
      listTitle: t("team.settings.list.first"),
      list: [
        t("team.settings.list.second"),
        t("team.settings.list.third"),
        t("team.settings.list.fourth"),
        t("team.settings.list.fifth")
      ],
      secondText: t("team.settings.subText")
    },
    {
      img: [settings3],
      text: t("team.settings.third")
    }
  ];
  return (
    <section className={"account help"}>
      <div onClick={() => setVisible(!visible)} className={`help__btn ${visible ? "help__btn--hide" : ""}`}>
        <svg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1.45481 10.1816C1.05315 10.1816 0.727539 9.85603 0.727539 9.45437C0.727539 9.05271 1.05315 8.7271 1.45481 8.7271H14.5457C14.9474 8.7271 15.273 9.05271 15.273 9.45437C15.273 9.85603 14.9474 10.1816 14.5457 10.1816H1.45481ZM1.2933 7.27246C0.836287 7.27246 0.618803 6.70991 0.956968 6.40249L7.32733 0.611254C7.70875 0.264507 8.29125 0.264508 8.67267 0.611255L15.043 6.40249C15.3812 6.70991 15.1637 7.27246 14.7067 7.27246H1.2933Z'
            fill='#DDDDDD'
          />
        </svg>
      </div>
      <h2 onClick={() => setVisible(!visible)} className={"account__title "}>
        {t("teams")}
      </h2>
      <div className={"account__info help__info"}>
        <div className={"account__name"}>
          <h4 onClick={() => setVisible(!visible)} className={"account__title help__title"}>
            {t("team.title")}
          </h4>
          {visible && (
            <>
              <ul className={"help__list"}>
                {startGuide.map(({ img, text, title }) => (
                  <li className={"help__item"} key={text}>
                    {title && <h4 className={"help__item--title"}>{title}</h4>}
                    <div className={"help__image"}>
                      <img src={img} alt={"guide"} />
                    </div>
                    <p className={"help__item--text"}>{text}</p>
                  </li>
                ))}
                <li className={"help__item"}>
                  <div className={"help__image"}>
                    <img src={team} alt={"guide"} />
                  </div>
                  <p className={"help__item--text"}>{t("team.members.subText")}</p>
                  <ul className={"help__sub-list"}>
                    <li className={"help__sub-list--item"}>{t("team.members.first")}</li>
                    <li className={"help__sub-list--item"}>{t("team.members.second")}</li>
                    <li className={"help__sub-list--item"}>{t("team.members.third")}</li>
                    <li className={"help__sub-list--item"}>{t("team.members.fourth")}</li>
                  </ul>
                </li>
                <li className={"help__item"}>
                  <h4 className={"help__item--title"}>{t("team.settings.title")}</h4>
                  <p className={"help__item--text"}>{t("team.settings.text")}</p>
                </li>
              </ul>
              <ul className={"help__list"}>
                {settingsGuide.map(({ img, text, secondText, list, listTitle }) => (
                  <li className={"help__item"} key={text}>
                    {img.map(i => (
                      <div key={i} className={"help__image"}>
                        <img src={i} alt={"guide"} />
                      </div>
                    ))}
                    <p className={"help__item--text"}>{text}</p>
                    {listTitle && <p className={"help__item--text"}>{listTitle}</p>}
                    {list && (
                      <ul className={"help__sub-list"}>
                        {list.map(item => (
                          <li key={item} className={"help__sub-list--item"}>
                            {item}
                          </li>
                        ))}
                      </ul>
                    )}
                    {secondText && <p className={"help__item--text"}>{secondText}</p>}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Teams;
