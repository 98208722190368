import { memo, useEffect, useState } from "react";
import styles from "./styles.module.css";
import { getIntersectionsBySearch } from "../../../../../../../../api/intersections/intersections";
import { useSelector } from "react-redux";
import { projectsSelector } from "../../../../../../../../store/slices/projects-slice";
import useDebounce from "../../../../../../../../hooks/useDebounce";
import { SpinnerCircular } from "spinners-react";

interface IProps {
  query: string;
}
const escapeHTML = (text: string) => {
  return text
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
};

const highlightMatch = (text: string, query: string) => {
  if (!query) return escapeHTML(text);
  const safeQuery = query.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  const regex = new RegExp(`(${safeQuery})`, "gi");

  return text
    .split(regex)
    .map((part, index) => (regex.test(part) ? `<mark>${escapeHTML(part)}</mark>` : escapeHTML(part)))
    .join("");
};

function IntersectionSearchResults({ query }: IProps) {
  const { project } = useSelector(projectsSelector);
  // @ts-ignore
  const [filteredResults, setFilteredResults] = useState<any>();
  const debouncedQuery = useDebounce(query, 500);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (query.length <= 3) {
      setFilteredResults([]);
    }
    if (query && query.length > 3) {
      setIsLoading(true);
    }
  }, [query]);

  useEffect(() => {
    if (debouncedQuery && debouncedQuery.length > 3) {
      getIntersectionsBySearch(project.entity.id, query)
        .then(res => {
          setFilteredResults(res?.data);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [debouncedQuery]);

  return (
    <div
      style={{
        opacity: query ? 1 : 0,
        pointerEvents: query ? "auto" : "none",
        top: query ? "60px" : "40px",
        maxHeight: query ? "500px" : "0px"
      }}
      className={`${styles.resultsContainer}`}
    >
      {query.length <= 3 && <div style={{ color: "red" }}>Too short query!</div>}
      {isLoading && query.length > 3 ? (
        <div className={styles.resultsContainerChild}>
          {" "}
          <SpinnerCircular />
        </div>
      ) : (
        query.length > 3 && (
          <>
            {filteredResults?.count === 0 && <p className={styles.noResults}>Ничего не найдено</p>}
            <div className={styles.summary}>
              <p>
                Найдено вхождений: <strong>{filteredResults?.count}</strong>
              </p>
            </div>
            {filteredResults &&
              Object.entries(filteredResults?.entries).map(([key, value]) => (
                <div key={key} className={styles.project}>
                  <h3>Файл: {key}</h3>
                  <br />

                  <div style={{display: "flex", flexDirection: 'column', gap: "20px"}}>
                    {
                      // @ts-ignore
                      value.map((el, i) => {
                        return (
                          <>
                            <h3>
                              Вхождение {++i}:{" "}
                              <p
                                className={styles.snippet}
                                // @ts-ignore
                                dangerouslySetInnerHTML={{ __html: highlightMatch(el?.source, debouncedQuery) }} // Подсветка
                              />
                            </h3>
                          </>
                        );
                      })
                    }
                  </div>
                </div>
              ))}
          </>
        )
      )}
    </div>
  );
}

export default memo(IntersectionSearchResults);
