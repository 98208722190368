import axios from "axios";
import { HOST } from "../constants/host";
import { getToken } from "../utils/authLocalStorage";
import { TOKEN } from "../constants/token";
import { history } from "../index";

const makeRequest = axios.create({
  baseURL: HOST,
  params: ""
});

makeRequest.interceptors.request.use(
  async config => {
    const token = getToken();
    if (token) {
      config.headers = {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: "Bearer " + token
      };
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

makeRequest.interceptors.response.use(
  async response => {
    return response;
  },
  error => {
    if (error.code === "ERR_NETWORK") {
      if (window.navigator.onLine) {
        window.location.href = `/maintenance`;
      } else {
        window.location.href = "/network-error";
      }
    }
    if (error.response.status === 401 && window.location.pathname !== "/auth") {
      localStorage.removeItem(TOKEN);
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

export default makeRequest;
