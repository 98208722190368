import { Action, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { IMemberSortState } from "../../interfaces/members-sort/members-sort";

const initialState: IMemberSortState = {
  members_options: {
    sort: {
      sortBy: "",
      sortValue: ""
    },
    page: 1
  }
};

export const membersOptionsSelector = (state: { members_options: IMemberSortState }) => state.members_options;

const membersOptionsSlice = createSlice({
  name: "members-sort",
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ sortBy: string; sortValue: string }>) => {
      state.members_options.sort.sortBy = action.payload.sortBy;
      state.members_options.sort.sortValue = action.payload.sortValue;
    },
    resetSort: state => {
      state.members_options.sort.sortBy = "";
      state.members_options.sort.sortValue = "";
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.members_options.page = action.payload;
    }
  }
});

export const { setSort, resetSort, setPage } = membersOptionsSlice.actions;

export default membersOptionsSlice.reducer;
