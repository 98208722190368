import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { IRootState } from "../interfaces";
import user from "./slices/user-slice";
import upload from "./slices/upload-project-slice";
import projects from "./slices/projects-slice";
import teams from "./slices/team-slice";
import sidebar from "./slices/sidebar-slice";
import counters from "./slices/counters-slice";
import categories from "./slices/categories-slice";
import hashes from "./slices/hash-slice";
import errors from "./slices/errors-slice";
import members from "./slices/members-slice";
import tickets from "./slices/tickets-slice";
import projectOptions from "./slices/project-filter-slice";
import ticketMessages from "./slices/messages-slice";
import ticketFilters from "./slices/ticket-filter-slice";
import provider from "./slices/oauth-slice";
import progress from "./slices/progress-slice";
import tabs from "./slices/tabs-slice";
import team_roles from "./slices/team-roles-slice";
import members_options from "./slices/members-sort-slice";

const reducer = combineReducers<IRootState>({
  user: user,
  upload: upload,
  projects: projects,
  teams: teams,
  sidebar: sidebar,
  counters: counters,
  categories: categories,
  hashes: hashes,
  errors: errors,
  members: members,
  tickets: tickets,
  projectOptions: projectOptions,
  ticketMessages: ticketMessages,
  ticketFilters: ticketFilters,
  provider: provider,
  progress: progress,
  tabs: tabs,
  team_roles: team_roles,
  members_options: members_options
});

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools: process.env.NODE_ENV === "development"
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
