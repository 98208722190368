import React, { useState } from "react";
import "./sign-in-form.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { auth, authError, userSelector } from "../../../../store/slices/user-slice";
import { useForm } from "react-hook-form";
import Loader from "../../../../shared/loader/loader";
import { removeToken, setToken } from "../../../../utils/authLocalStorage";
import { userAuth } from "../../../../api/user/user";
import { AxiosResponse } from "axios";
import { IAuth } from "../../../../interfaces/user/user";
import Ouath from "./components/oauth/ouath";
import ResetPasswordModal from "../reset-password-modal";
import ConfirmCode from "../confirm-code";
import { IDataConfirm } from "../sign-up-form/sign-up-form";
import { emailPattern, passwordPattern } from "../../../../constants/patterns";
import { useTranslation } from "react-i18next";

interface props {}

const SignInForm: React.FC<props> = ({}) => {
  const navigate = useNavigate();
  const { user } = useSelector(userSelector);
  const { t } = useTranslation();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [data, setData] = useState<IDataConfirm>({
    username: "",
    password: ""
  });
  const showHandler = () => {
    document.body.style.overflow = "hidden";
    setConfirm(true);
  };
  const hideHandler = () => {
    setConfirm(false);
    document.body.style.overflow = "visible";
  };
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>("");
  const handleChange = () => setIsShowPassword(!isShowPassword);
  const onSubmit = (data: any) => {
    setConfirmLoading(true);
    userAuth(data)
      .then((response: AxiosResponse<IAuth>) => {
        if (response && response.data) {
          dispatch(auth(response.data.user));
          setToken(response.data.token);
          reset();
          navigate("/projects");
        }
      })
      .catch((error: any) => {
        if (error && error.response.status === 424) {
          setData(data);
          showHandler();
        }
        if (error && error.response) {
          dispatch(authError(error.response.data.message));
        }
        removeToken();
      })
      .finally(() => setConfirmLoading(false));
  };

  function successHandler(msg: string) {
    setSuccess(msg);
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={"sign-form"}>
        {confirmLoading && <Loader />}
        <div className={"sign-form__input-wrapper"}>
          <label className={"sign-form__label"}>{t("email")}</label>
          <input
            {...register("username", {
              required: {
                value: true,
                message: t("required")
              },
              pattern: {
                value: emailPattern,
                message: t("emailError")
              }
            })}
            type={"email"}
            className={"sign-form__input"}
            placeholder={t("emailPlaceholder")}
          />

          {errors.username && errors.username.type.length > 0 && (
            <p className={"password__error"}>{errors.username.message}</p>
          )}
        </div>
        <div className={"sign-form__input-wrapper"}>
          <label className={"sign-form__label"}>{t("password")}</label>
          <div className={"password__show__wrapper"}>
            <input
              {...register("password", {
                required: {
                  value: true,
                  message: t("required")
                },
                min: 8
              })}
              type={isShowPassword ? "text" : "password"}
              className={"sign-form__input"}
              placeholder={t("passwordPlaceholder")}
            />
            <svg
              onClick={handleChange}
              className={"password__show"}
              width='16'
              height='19'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M10.5 8C10.5 8.66304 10.2366 9.29893 9.76777 9.76777C9.29893 10.2366 8.66304 10.5 8 10.5C7.33696 10.5 6.70107 10.2366 6.23223 9.76777C5.76339 9.29893 5.5 8.66304 5.5 8C5.5 7.33696 5.76339 6.70107 6.23223 6.23223C6.70107 5.76339 7.33696 5.5 8 5.5C8.66304 5.5 9.29893 5.76339 9.76777 6.23223C10.2366 6.70107 10.5 7.33696 10.5 8Z'
                fill='#DDDDDD'
              />
              <path
                d='M0 8C0 8 3 2.5 8 2.5C13 2.5 16 8 16 8C16 8 13 13.5 8 13.5C3 13.5 0 8 0 8ZM8 11.5C8.92826 11.5 9.8185 11.1313 10.4749 10.4749C11.1313 9.8185 11.5 8.92826 11.5 8C11.5 7.07174 11.1313 6.1815 10.4749 5.52513C9.8185 4.86875 8.92826 4.5 8 4.5C7.07174 4.5 6.1815 4.86875 5.52513 5.52513C4.86875 6.1815 4.5 7.07174 4.5 8C4.5 8.92826 4.86875 9.8185 5.52513 10.4749C6.1815 11.1313 7.07174 11.5 8 11.5V11.5Z'
                fill='#DDDDDD'
              />
            </svg>
          </div>
        </div>
        {errors.password && errors.password.type.length > 0 && (
          <p className={"password__error"}>{errors.password.message}</p>
        )}
        <div className={"sign-form__remember"}>
          <label className={"remember__label"}>
            {t("rememberPass")}
            <input {...register("_remember_me", { value: false })} type={"checkbox"} className={"remember__checkbox"} />
            <span className={"remember__checkmark"} />
          </label>
          <ResetPasswordModal successHandler={successHandler} />
        </div>
        {success.length > 0 && <p className={"upload-form__success"}>{success}</p>}
        <button className={"sign-form__btn"}>{t("enter")}</button>
        {user.errors.isError && <p className={"upload-form__error"}>{user.errors.message}</p>}
      </form>
      <ConfirmCode data={data} confirm={confirm} hideHandler={hideHandler} />
      <Ouath />
      <p className={"sign-form__haveNoAcc"}>{t("haveNoAccount")}</p>
      <Link to={`/register`} className={"sign-form__haveNoAcc register"}>
        {t("register")}
      </Link>
      <Link className={"login__link"} to={"/privacy-policy"}>
        {t("privacyAndPolicy")}
      </Link>
    </>
  );
};

export default SignInForm;
