import React, { useEffect, useState } from "react";
import "./errors.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  errorsSelector,
  errorsWrongs,
  getErrors,
  getErrorsThunk,
  resetErrors
} from "../../../../../../store/slices/errors-slice";
import { getErrorsApi } from "../../../../../../api/project/errors";
import Loader from "../../../../../../shared/loader/loader";
import { Pagination } from "../../../../../../shared/pagination";
import ErrorsItem from "./components/errorsItem";
import { useTranslation } from "react-i18next";

interface props {}

const Errors: React.FC<props> = () => {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const { errors } = useSelector(errorsSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handlePageChange = (count: number) => {
    setPage(count);
  };

  function request() {
    if (id) {
      setConfirmLoading(true);
      getErrorsApi(id, `?page=${page}`)
        .then(response => {
          if (response && response.data) {
            dispatch(getErrors(response.data));
            window.scrollTo(0, 500);
          }
        })
        .catch(e => {
          if (e.response && e.response.data) {
            dispatch(errorsWrongs(e.response.data.detail));
          }
        })
        .finally(() => setConfirmLoading(false));
    }
  }

  useEffect(() => {
    if (id) {
      dispatch(getErrorsThunk(id));
    }
    return () => {
      dispatch(resetErrors());
    };
  }, []);

  useEffect(() => {
    request();
  }, [page]);
  if (!errors.loaded) {
    return <Loader />;
  }
  return (
    <>
      <div className={"errors__head"}>{t("errors")}</div>
      <div className={"errors__body"}>
        {confirmLoading && <Loader />}
        {errors.entity.items.length ? (
          errors.entity.items.map(error => <ErrorsItem error={error} key={error.id} />)
        ) : (
          <div>{t("noErrors")}</div>
        )}
        {errors.entity.items.length ? (
          <Pagination
            current={page}
            onChange={handlePageChange}
            last={errors.entity.pages_count}
            hasNext={errors.entity.pages_count > page}
            disabled={confirmLoading}
          />
        ) : null}
      </div>
    </>
  );
};

export default Errors;
