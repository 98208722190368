import React from "react";

interface props {}

const InformationCollection: React.FC<props> = () => {
  const links = [
    {
      id: 1,
      title: "Google Play Services",
      path: "https://play.google.com/store/apps/details?id=com.google.android.gms&hl=en&gl=US"
    },
    {
      id: 2,
      title: "AdMob",
      path: "https://admob.google.com/home/"
    },
    {
      id: 3,
      title: "Google Analytics for Firebase",
      path: "https://firebase.google.com/products/analytics?gclid=CjwKCAjwzeqVBhAoEiwAOrEmzaGgPxtdQCwYkhar-YmFHkWa56XSorQOrNBUs_enZsePIHVShRZWoBoCcJMQAvD_BwE&gclsrc=aw.ds"
    },
    {
      id: 4,
      title: "Firebase Crashlytics",
      path: "https://firebase.google.com/products/crashlytics?gclid=CjwKCAjwzeqVBhAoEiwAOrEmzW4z6zQZGdUovCRLN9KK_j8SB5mxvxRVLxzC4H05xvsERPE3Yb8VghoCJoEQAvD_BwE&gclsrc=aw.ds"
    },
    {
      id: 5,
      title: "Facebook",
      path: "https://www.facebook.com/"
    }
  ];
  return (
    <section className={"account privacy"}>
      <h2 className={"account__title"}>Information Collection and Use</h2>
      <div className={"account__info"}>
        <p className={"privacy__text"}>
          For a better experience, while using our Service, I may require you to provide us with certain personally
          identifiable information. The information that I request will be retained on your device and is not collected
          by me in any way. The app does use third-party services that may collect information used to identify you.
          Link to the privacy policy of third-party service providers used by the app
        </p>
        <ul className={"privacy__list"}>
          {links.map(({ title, id, path }) => (
            <li key={id}>
              <a href={path} target={"_blank"} rel={"noreferrer"}>
                {title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default InformationCollection;
