import React, { useState } from "react";
import "../project-item-column.css";
import { useDispatch, useSelector } from "react-redux";
import { getProjectsThunk, getProjectThunk } from "../../../../../../../../store/slices/projects-slice";
import Loader from "../../../../../../../../shared/loader/loader";
import { useForm } from "react-hook-form";
import { IProject } from "../../../../../../../../interfaces/projects/projects";
import "./edit-project.css";
import { useNavigate, useParams } from "react-router-dom";
import { renameProjectApi } from "../../../../../../../../api/project/project";
import ProjectDeleteModal from "../../../project-delete-modal";
import { filterSelector } from "../../../../../../../../store/slices/project-filter-slice";
import { useTranslation } from "react-i18next";

interface props {
  projectItem: IProject;
  isProjectPage?: boolean;
}

const EditProject: React.FC<props> = ({ projectItem, isProjectPage }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const { projectOptions } = useSelector(filterSelector);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { teamId, id } = useParams();

  const showHandler = () => {
    setVisible(true);
    document.body.style.overflow = "hidden";
  };
  const hideHandler = () => {
    setVisible(false);
    document.body.style.overflow = "visible";
  };
  const redirect = () => {
    navigate(teamId ? `/teams/${teamId}/projects` : "/projects");
  };

  const onSubmit = (e: any) => {
    setConfirmLoading(true);
    renameProjectApi(projectItem.id, e)
      .then(response => {
        if (response && response.status === 200) {
          if (isProjectPage && id) {
            dispatch(getProjectThunk(id));
            hideHandler();
          } else {
            dispatch(getProjectsThunk(projectOptions.page, teamId ? `&teamID=${teamId}` : ""));
            hideHandler();
          }
        }
      })
      .finally(() => setConfirmLoading(false));
  };
  return (
    <div className={`project-database__item--btn-wrapper ${isProjectPage ? "edit-project__wrapper" : ""}`}>
      {confirmLoading && <Loader />}
      {isProjectPage ? (
        <button onClick={showHandler} className={"edit-project__btn"}>
          <svg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0.494141 7.30078C0.494141 7.85547 0.642578 8.2793 0.939453 8.57227C1.24023 8.86133 1.66602 9.00586 2.2168 9.00586H11.9082C12.4668 9.00586 12.8945 8.86133 13.1914 8.57227C13.4922 8.2793 13.6426 7.85547 13.6426 7.30078V2.23242C13.6426 1.68164 13.4922 1.26172 13.1914 0.972656C12.8945 0.679688 12.4668 0.533203 11.9082 0.533203H2.2168C1.66602 0.533203 1.24023 0.679688 0.939453 0.972656C0.642578 1.26172 0.494141 1.68164 0.494141 2.23242V7.30078ZM4.03906 5.67773C3.78906 5.67773 3.57422 5.58984 3.39453 5.41406C3.21875 5.23438 3.13086 5.01953 3.13086 4.76953C3.13086 4.51953 3.21875 4.30664 3.39453 4.13086C3.57422 3.95117 3.78906 3.86133 4.03906 3.86133C4.29297 3.86133 4.50781 3.95117 4.68359 4.13086C4.85938 4.30664 4.94727 4.51953 4.94727 4.76953C4.94727 5.01953 4.85938 5.23438 4.68359 5.41406C4.50781 5.58984 4.29297 5.67773 4.03906 5.67773ZM7.07422 5.67773C6.82031 5.67773 6.60352 5.58984 6.42383 5.41406C6.24805 5.23438 6.16016 5.01953 6.16016 4.76953C6.16016 4.51953 6.24805 4.30664 6.42383 4.13086C6.60352 3.95117 6.82031 3.86133 7.07422 3.86133C7.32422 3.86133 7.53711 3.95117 7.71289 4.13086C7.89258 4.30664 7.98242 4.51953 7.98242 4.76953C7.98242 5.01953 7.89258 5.23438 7.71289 5.41406C7.53711 5.58984 7.32422 5.67773 7.07422 5.67773ZM10.1035 5.67773C9.84961 5.67773 9.63477 5.58984 9.45898 5.41406C9.2832 5.23438 9.19531 5.01953 9.19531 4.76953C9.19531 4.51953 9.2832 4.30664 9.45898 4.13086C9.63477 3.95117 9.84961 3.86133 10.1035 3.86133C10.3535 3.86133 10.5664 3.95117 10.7422 4.13086C10.9219 4.30664 11.0117 4.51953 11.0117 4.76953C11.0117 5.01953 10.9219 5.23438 10.7422 5.41406C10.5664 5.58984 10.3535 5.67773 10.1035 5.67773Z'
              fill='black'
            />
          </svg>
          {t("editProject")}
        </button>
      ) : (
        <button onClick={showHandler} className={"project-database__item--btn "}>
          <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M4.09766 9.50586C4.09766 9.00195 3.92188 8.57715 3.57031 8.23145C3.22461 7.88574 2.79688 7.71289 2.28711 7.71289C1.95312 7.71289 1.64844 7.79492 1.37305 7.95898C1.10352 8.11719 0.886719 8.33398 0.722656 8.60938C0.564453 8.87891 0.485352 9.17773 0.485352 9.50586C0.485352 9.83398 0.564453 10.1328 0.722656 10.4023C0.886719 10.6719 1.10352 10.8887 1.37305 11.0527C1.64844 11.2168 1.95312 11.2988 2.28711 11.2988C2.61523 11.2988 2.91699 11.2168 3.19238 11.0527C3.46777 10.8887 3.6875 10.6719 3.85156 10.4023C4.01562 10.1328 4.09766 9.83398 4.09766 9.50586ZM10.3115 9.50586C10.3115 9.00195 10.1387 8.57715 9.79297 8.23145C9.44727 7.88574 9.02246 7.71289 8.51855 7.71289C8.18457 7.71289 7.88281 7.79492 7.61328 7.95898C7.34375 8.11719 7.12695 8.33398 6.96289 8.60938C6.80469 8.87891 6.72559 9.17773 6.72559 9.50586C6.72559 9.83398 6.80469 10.1328 6.96289 10.4023C7.12695 10.6719 7.34375 10.8887 7.61328 11.0527C7.88281 11.2168 8.18457 11.2988 8.51855 11.2988C8.84668 11.2988 9.14551 11.2168 9.41504 11.0527C9.68457 10.8887 9.90137 10.6719 10.0654 10.4023C10.2295 10.1328 10.3115 9.83398 10.3115 9.50586ZM16.543 9.50586C16.543 9.00195 16.3701 8.57715 16.0244 8.23145C15.6787 7.88574 15.251 7.71289 14.7412 7.71289C14.4072 7.71289 14.1025 7.79492 13.8271 7.95898C13.5576 8.11719 13.3408 8.33398 13.1768 8.60938C13.0127 8.87891 12.9307 9.17773 12.9307 9.50586C12.9307 9.83398 13.0127 10.1328 13.1768 10.4023C13.3408 10.6719 13.5576 10.8887 13.8271 11.0527C14.1025 11.2168 14.4072 11.2988 14.7412 11.2988C15.0752 11.2988 15.377 11.2168 15.6465 11.0527C15.9219 10.8887 16.1387 10.6719 16.2969 10.4023C16.4609 10.1328 16.543 9.83398 16.543 9.50586Z'
              fill='white'
            />
          </svg>
        </button>
      )}
      <div className={`member-add__wrapper ${visible ? "member-add__wrapper--show" : ""}`}>
        <div onClick={hideHandler} className={`member-add__backdrop ${visible ? "member-add__backdrop--show" : ""}`} />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`member-add__form ${visible ? `member-add__form--show` : ""}`}
        >
          <div onClick={hideHandler} className={"member-add__form--close"}>
            <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M1.0625 11.8203C1.13542 11.8984 1.22396 11.9479 1.32812 11.9688C1.43229 11.9948 1.53385 11.9948 1.63281 11.9688C1.73177 11.9479 1.82292 11.8984 1.90625 11.8203L6.69531 7.02344L11.4922 11.8203C11.6068 11.9349 11.7474 11.9922 11.9141 11.9922C12.0807 11.9974 12.2214 11.9401 12.3359 11.8203C12.4505 11.7005 12.5078 11.5573 12.5078 11.3906C12.5078 11.224 12.4505 11.0859 12.3359 10.9766L7.53906 6.17969L12.3359 1.38281C12.4505 1.27344 12.5078 1.13542 12.5078 0.96875C12.513 0.796875 12.4557 0.653646 12.3359 0.539062C12.2214 0.424479 12.0807 0.367188 11.9141 0.367188C11.7474 0.367188 11.6068 0.424479 11.4922 0.539062L6.69531 5.33594L1.90625 0.539062C1.82292 0.466146 1.73177 0.416667 1.63281 0.390625C1.53385 0.364583 1.43229 0.364583 1.32812 0.390625C1.22396 0.411458 1.13542 0.460938 1.0625 0.539062C0.984375 0.617188 0.932292 0.708333 0.90625 0.8125C0.880208 0.911458 0.880208 1.01302 0.90625 1.11719C0.932292 1.22135 0.984375 1.3099 1.0625 1.38281L5.85156 6.17969L1.0625 10.9766C0.984375 11.0495 0.929688 11.138 0.898438 11.2422C0.872396 11.3464 0.872396 11.4505 0.898438 11.5547C0.924479 11.6589 0.979167 11.7474 1.0625 11.8203Z'
                fill='#A9A9A8'
              />
            </svg>
          </div>
          <div className={"member-add__form--top"}>
            <h2 className={"member-add__form--title"}>{t("projectProp")}</h2>
          </div>
          {visible && (
            <div className={"member-add__form--email"}>
              <label htmlFor={"emailLabel"} className={"member-add__form--email-label"}>
                {t("projectName")}
              </label>
              <input
                {...register("name", {
                  required: {
                    value: true,
                    message: t("required")
                  },
                  maxLength: {
                    value: 50,
                    message: t("projectNameMax")
                  },
                  value: projectItem.name,
                  onBlur: e => setValue("name", e.target.value.trim())
                })}
                id={"emailLabel"}
                className={"member-add__form--email-input edit-project__name"}
                placeholder={"Project name"}
              />
            </div>
          )}
          <p className={"member-add__form--error"}>{errors.name?.message}</p>
          {visible && (
            <div className={"member-add__form--email"}>
              <label htmlFor={"emailLabel"} className={"member-add__form--email-label"}>
                {t("platform")}
              </label>
              <input
                id={"emailLabel"}
                readOnly={true}
                value={projectItem.type}
                className={"member-add__form--email-input edit-project__type"}
                placeholder={"Platform"}
              />
            </div>
          )}
          {projectItem.team ? (
            <div className={"member-add__form--email"}>
              <p className={"member-add__form--email-label"}>{t("openedFor")}</p>
              <p className={"edit-project__team"}>{projectItem.team.name}</p>
            </div>
          ) : null}
          <button type={"submit"} className={"member-add__form--btn"}>
            {t("save")}
          </button>
          <ProjectDeleteModal
            hide={hideHandler}
            isProjectPage={isProjectPage}
            redirect={redirect}
            projectItem={projectItem}
          />
        </form>
      </div>
    </div>
  );
};

export default EditProject;
