import React, { useEffect } from "react";
import Loader from "../../shared/loader/loader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { providerSelector } from "../../store/slices/oauth-slice";
import makeRequest from "../../api/makeRequest";
import { auth } from "../../store/slices/user-slice";
import { setToken } from "../../utils/authLocalStorage";

interface props {}

const GetAccessCode: React.FC<props> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { provider } = useSelector(providerSelector);
  const dispatch = useDispatch();
  const code = searchParams.get("code");
  const scope = searchParams.get("scope");
  const authuser = searchParams.get("authuser");
  const hd = searchParams.get("hd");
  const prompt = searchParams.get("prompt");
  const navigate = useNavigate();

  useEffect(() => {
    if (code && scope && authuser && hd && prompt) {
      makeRequest
        .get(
          `https://api.apprefactoring.com/oauth/login/check-google?code=${code}&scope=${scope}&authuser=${authuser}&hd=${hd}&prompt=${prompt}`
        )
        .then(response => {
          if (response && response.data) {
            dispatch(auth(response.data.user));
            setToken(response.data.token);
            navigate("/projects");
          }
        });
    }
  }, [scope, code, hd, prompt, authuser, provider.entity.auth_url]);

  return (
    <section className={"login"}>
      <div className={"login-top"}></div>
      <Loader />
    </section>
  );
};

export default GetAccessCode;
