import React, { useEffect, useState } from "react";
import { IHash } from "../../../../../../../interfaces/hashes/hashes";
import { useParams } from "react-router-dom";
import { ignoreHash, unIgnoreHash } from "../../../../../../../api/hashes/hashes";
import { useDispatch, useSelector } from "react-redux";
import { getHashesThunk, hashSelector } from "../../../../../../../store/slices/hash-slice";
import Loader from "../../../../../../../shared/loader/loader";
import { userSelector } from "../../../../../../../store/slices/user-slice";
import { useTranslation } from "react-i18next";
import { teamSelector } from "../../../../../../../store/slices/team-slice";

interface props {
  hashItem: IHash;
  ignore: boolean;
  index: number;
}

const HashLabel: React.FC<props> = ({ hashItem, ignore, index }) => {
  const { target, source, hash } = hashItem;
  const { hashes } = useSelector(hashSelector);
  const { user } = useSelector(userSelector);
  const { t } = useTranslation();
  const { teamId } = useParams();
  const { team } = useSelector(teamSelector);
  const [error, setError] = useState<string>("");
  const dispatch = useDispatch();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  useEffect(() => {
    setError("");
  }, [ignore]);
  const ignoreHandler = () => {
    if (hashes.id && !ignore) {
      setConfirmLoading(true);
      ignoreHash(hashes.id.toString(), hash)
        .then(response => {
          if (hashes.id) {
            dispatch(getHashesThunk(hashes.id.toString(), hashes.count, ignore));
          }
        })
        .catch(e => {
          if (e.response && e.response.data) {
            setError(e.response.data.message);
          }
        })
        .finally(() => setConfirmLoading(false));
    } else if (hashes.id && ignore) {
      setConfirmLoading(true);
      unIgnoreHash(hashes.id.toString(), hash)
        .then(response => {
          if (hashes.id) {
            dispatch(getHashesThunk(hashes.id.toString(), hashes.count, ignore));
          }
        })
        .catch(e => {
          if (e.response && e.response.data) {
            setError(e.response.data.message);
          }
        })
        .finally(() => setConfirmLoading(false));
    }
  };
  const permission = user.entity.role?.find(item => item.teamID.toString() === teamId)?.permissions;
  return (
    <div className={`hash__wrapper hash__wrapper--danger`}>
      {confirmLoading && <Loader />}
      {teamId ? (
        user.entity.id === team.entity?.owner?.id || (permission && permission.canIgnoreHash) ? (
          <div className={"hash__ignore--wrapper"}>
            <button onClick={ignoreHandler} className={"hash__ignore"}>
              {ignore ? t("unIgnore") : t("ignore")}
            </button>
            <svg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M1.88477 12.9219H12.0361C12.3551 12.9219 12.6309 12.8512 12.8633 12.71C13.1003 12.5687 13.2826 12.3796 13.4102 12.1426C13.5378 11.9056 13.6016 11.6436 13.6016 11.3564C13.6016 11.2243 13.5833 11.0944 13.5469 10.9668C13.515 10.8392 13.4626 10.7139 13.3896 10.5908L8.30371 1.51953C8.16243 1.25521 7.96875 1.05697 7.72266 0.924805C7.48112 0.792643 7.22591 0.726562 6.95703 0.726562C6.69727 0.726562 6.44434 0.792643 6.19824 0.924805C5.95215 1.05697 5.75618 1.25521 5.61035 1.51953L0.524414 10.5908C0.387695 10.846 0.319336 11.1012 0.319336 11.3564C0.319336 11.6436 0.383138 11.9056 0.510742 12.1426C0.638346 12.3796 0.818359 12.5687 1.05078 12.71C1.28776 12.8512 1.56576 12.9219 1.88477 12.9219ZM6.9707 8.61523C6.65169 8.61523 6.48991 8.45117 6.48535 8.12305L6.40332 4.60254C6.39876 4.44759 6.44889 4.31999 6.55371 4.21973C6.65853 4.11491 6.79525 4.0625 6.96387 4.0625C7.12793 4.0625 7.26237 4.11491 7.36719 4.21973C7.47201 4.32454 7.52441 4.45443 7.52441 4.60938L7.43555 8.12305C7.43555 8.45117 7.2806 8.61523 6.9707 8.61523ZM6.9707 10.7344C6.78385 10.7344 6.62207 10.6706 6.48535 10.543C6.35319 10.4154 6.28711 10.2627 6.28711 10.085C6.28711 9.90267 6.35319 9.74772 6.48535 9.62012C6.62207 9.48796 6.78385 9.42188 6.9707 9.42188C7.15299 9.42188 7.31022 9.48568 7.44238 9.61328C7.5791 9.74089 7.64746 9.89811 7.64746 10.085C7.64746 10.2673 7.5791 10.4222 7.44238 10.5498C7.30566 10.6729 7.14844 10.7344 6.9707 10.7344Z'
                fill='#FF333C'
              />
            </svg>
          </div>
        ) : null
      ) : (
        <div className={"hash__ignore--wrapper"}>
          <button onClick={ignoreHandler} className={"hash__ignore"}>
            {ignore ? t("unIgnore") : t("ignore")}
          </button>
          <svg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M1.88477 12.9219H12.0361C12.3551 12.9219 12.6309 12.8512 12.8633 12.71C13.1003 12.5687 13.2826 12.3796 13.4102 12.1426C13.5378 11.9056 13.6016 11.6436 13.6016 11.3564C13.6016 11.2243 13.5833 11.0944 13.5469 10.9668C13.515 10.8392 13.4626 10.7139 13.3896 10.5908L8.30371 1.51953C8.16243 1.25521 7.96875 1.05697 7.72266 0.924805C7.48112 0.792643 7.22591 0.726562 6.95703 0.726562C6.69727 0.726562 6.44434 0.792643 6.19824 0.924805C5.95215 1.05697 5.75618 1.25521 5.61035 1.51953L0.524414 10.5908C0.387695 10.846 0.319336 11.1012 0.319336 11.3564C0.319336 11.6436 0.383138 11.9056 0.510742 12.1426C0.638346 12.3796 0.818359 12.5687 1.05078 12.71C1.28776 12.8512 1.56576 12.9219 1.88477 12.9219ZM6.9707 8.61523C6.65169 8.61523 6.48991 8.45117 6.48535 8.12305L6.40332 4.60254C6.39876 4.44759 6.44889 4.31999 6.55371 4.21973C6.65853 4.11491 6.79525 4.0625 6.96387 4.0625C7.12793 4.0625 7.26237 4.11491 7.36719 4.21973C7.47201 4.32454 7.52441 4.45443 7.52441 4.60938L7.43555 8.12305C7.43555 8.45117 7.2806 8.61523 6.9707 8.61523ZM6.9707 10.7344C6.78385 10.7344 6.62207 10.6706 6.48535 10.543C6.35319 10.4154 6.28711 10.2627 6.28711 10.085C6.28711 9.90267 6.35319 9.74772 6.48535 9.62012C6.62207 9.48796 6.78385 9.42188 6.9707 9.42188C7.15299 9.42188 7.31022 9.48568 7.44238 9.61328C7.5791 9.74089 7.64746 9.89811 7.64746 10.085C7.64746 10.2673 7.5791 10.4222 7.44238 10.5498C7.30566 10.6729 7.14844 10.7344 6.9707 10.7344Z'
              fill='#FF333C'
            />
          </svg>
        </div>
      )}
      {error.length > 0 && <p className={"hash__ignore--error"}>{error}</p>}
      <div className={"hash__items"}>
        <div className={"hash__item"}>
          <div className={"hash__code"}>
            <p className={"hash__code--title"}>Hash</p> <p className={"hash__code--value"}>{hash}</p>
          </div>
          {source?.map(({ column, context, filepath, line, value }, index) => {
            return (
              <div className={"hash__item--wrapper"} key={`${filepath}${index}`}>
                <div className={"hash__item--info"}>{filepath}</div>
                {context && (
                  <div className='popover__wrapper'>
                    <h2 className='popover__title'>{value}</h2>
                    <div className='popover__content'>
                      <pre className='popover__message'>
                        <code>{context}</code>
                      </pre>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className={"hash__item"}>
          <div className={"hash__code"}>
            <p className={"hash__code--title"}>Hash</p> <p className={"hash__code--value"}>{hash}</p>
          </div>
          {target?.map(({ column, context, filepath, line, value }, index) => (
            <div className={"hash__item--wrapper"} key={`${filepath} ${index + 1}`}>
              <div className={"hash__item--info"}>{filepath}</div>
              {context && (
                <div className='popover__wrapper'>
                  <h2 className='popover__title'>{value}</h2>
                  <div className='popover__content popover__content--right'>
                    <code className='popover__message'>
                      <pre>{context}</pre>
                    </code>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HashLabel;
