import React, { useEffect, useRef, useState } from "react";
import "./sign-up-form.css";
import { useForm } from "react-hook-form";
import { userRegistration } from "../../../../api/user/user";
import Loader from "../../../../shared/loader/loader";
import { Link } from "react-router-dom";
import Ouath from "../sign-in-form/components/oauth/ouath";
import ConfirmCode from "../confirm-code";
import ReCAPTCHA from "react-google-recaptcha";
import { oneLetterPattern, oneNumberPattern, usernamePatten } from "../../../../constants/patterns";
import { useTranslation } from "react-i18next";

interface props {}

export interface IDataConfirm {
  username: string;
  password: string;
}

const SignUpForm: React.FC<props> = () => {
  const emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { t } = useTranslation();
  const [password, setPassword] = useState<string | null>(null);
  const [minLength, setMinLength] = useState<string>("");
  const [oneLetter, setOneLetter] = useState<string>("");
  const [oneNumber, setOneNumber] = useState<string>("");
  const [captcha, setCaptcha] = useState<string>("");

  const [data, setData] = useState<IDataConfirm>({
    username: "",
    password: ""
  });
  const [isShowPassword, setIsShowPassword] = useState({
    password: false
  });
  useEffect(() => {
    if (password) {
      if (password.trim().length < 8) {
        setMinLength("error");
      } else if (password.trim().length >= 8) {
        setMinLength("success");
      }
    } else {
      setMinLength("");
    }
  }, [password]);
  useEffect(() => {
    if (password) {
      if (oneNumberPattern.test(password)) {
        setOneNumber("success");
      } else if (!oneNumberPattern.test(password)) {
        setOneNumber("error");
      }
    } else {
      setOneNumber("");
    }
  }, [password]);
  useEffect(() => {
    if (password) {
      if (oneLetterPattern.test(password)) {
        setOneLetter("success");
      } else if (!oneLetterPattern.test(password)) {
        setOneLetter("error");
      }
    } else {
      setOneLetter("");
    }
  }, [password]);
  const showHandler = () => {
    document.body.style.overflow = "hidden";
    setConfirm(true);
  };
  const hideHandler = () => {
    setConfirm(false);
    document.body.style.overflow = "visible";
  };
  const handleChange = (name: string, value: boolean) => {
    setIsShowPassword({
      ...isShowPassword,
      [name]: value
    });
  };
  const onSubmit = (e: any) => {
    if (password) {
      if (!captcha.length) {
        setError(t("passRecaptcha"));
        return;
      }
      if (minLength === "success" && oneLetter === "success" && oneNumber === "success") {
        const data = {
          ...e,
          password: password,
          recaptcha: captcha
        };
        setConfirmLoading(true);
        const confirmData: IDataConfirm = {
          username: e.email,
          password: password
        };
        userRegistration(data)
          .then(response => {
            if (response && response.data) {
              setData(confirmData);
              showHandler();
            }
          })
          .catch((error: any) => {
            setError(error.response.data.message);
          })
          .finally(() => setConfirmLoading(false));
      }
    }
  };
  const recaptchaRef: any = useRef<ReCAPTCHA>();
  const onReCAPTCHAChange = (captchaCode: string | null) => {
    // If the reCAPTCHA code is null or undefined indicating that
    // the reCAPTCHA was expired then return early
    if (!captchaCode) {
      setCaptcha("");
      return;
    }
    // Else reCAPTCHA was executed successfully so proceed with the
    // alert
    if (captchaCode) {
      setCaptcha(captchaCode);
    }

    // Reset the reCAPTCHA so that it can be executed again if user
    // submits another email.
  };

  const onChange = (e: any) => setPassword(e.target.value);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={"sign-form"}>
        {confirmLoading && <Loader />}
        <div className={"sign-form__input-wrapper register-form__input-wrapper"}>
          <label className={"sign-form__label"}>{t("createName")}</label>
          <input
            {...register("username", {
              required: true,
              min: 3,
              pattern: usernamePatten
            })}
            type={"text"}
            className={"sign-form__input"}
            placeholder={t("createNamePlace")}
          />
        </div>
        {errors.username && errors.username.type === "pattern" && (
          <p className={"password__error"}>{t("usernameRule")}</p>
        )}
        <div className={"sign-form__input-wrapper register-form__input-wrapper"}>
          <label className={"sign-form__label"}>{t("email")}</label>
          <input
            {...register("email", {
              required: true,
              pattern: emailPattern
            })}
            type={"email"}
            className={"sign-form__input"}
            placeholder={t("emailPlaceholder")}
          />
        </div>
        {errors.email && errors.email.type === "pattern" && <p className={"password__error"}>{t("emailError")}</p>}
        <div className={"sign-form__input-wrapper register-form__input-wrapper"}>
          <label className={"sign-form__label"}>{t("createPass")}</label>
          <div className={"password__show__wrapper"}>
            <input
              onChange={onChange}
              value={password ? password : ""}
              type={isShowPassword.password ? "text" : "password"}
              className={"sign-form__input"}
              placeholder={"Enter password"}
            />
            <svg
              onClick={() => handleChange("password", !isShowPassword.password)}
              className={"password__show"}
              width='16'
              height='19'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M10.5 8C10.5 8.66304 10.2366 9.29893 9.76777 9.76777C9.29893 10.2366 8.66304 10.5 8 10.5C7.33696 10.5 6.70107 10.2366 6.23223 9.76777C5.76339 9.29893 5.5 8.66304 5.5 8C5.5 7.33696 5.76339 6.70107 6.23223 6.23223C6.70107 5.76339 7.33696 5.5 8 5.5C8.66304 5.5 9.29893 5.76339 9.76777 6.23223C10.2366 6.70107 10.5 7.33696 10.5 8Z'
                fill='#DDDDDD'
              />
              <path
                d='M0 8C0 8 3 2.5 8 2.5C13 2.5 16 8 16 8C16 8 13 13.5 8 13.5C3 13.5 0 8 0 8ZM8 11.5C8.92826 11.5 9.8185 11.1313 10.4749 10.4749C11.1313 9.8185 11.5 8.92826 11.5 8C11.5 7.07174 11.1313 6.1815 10.4749 5.52513C9.8185 4.86875 8.92826 4.5 8 4.5C7.07174 4.5 6.1815 4.86875 5.52513 5.52513C4.86875 6.1815 4.5 7.07174 4.5 8C4.5 8.92826 4.86875 9.8185 5.52513 10.4749C6.1815 11.1313 7.07174 11.5 8 11.5V11.5Z'
                fill='#DDDDDD'
              />
            </svg>
          </div>
        </div>
        <div className={"password__rules"}>
          <p className={"password__rule"}>{t("passRules.title")}</p>
          <p
            className={`password__rule ${minLength === "error" ? "password__rules--wrong" : ""} ${minLength === "success" ? "password__rules--pass" : ""}`}
          >
            {t("passRules.minLength")}
            {minLength === "success" && (
              <span>
                <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M4.0542 9.97754C4.4445 9.97754 4.73991 9.82894 4.94043 9.53174L9.65088 2.38281C9.72607 2.27181 9.77979 2.16439 9.81201 2.06055C9.84424 1.95312 9.86035 1.85107 9.86035 1.75439C9.86035 1.48942 9.76904 1.26921 9.58643 1.09375C9.40739 0.914714 9.18001 0.825195 8.9043 0.825195C8.7181 0.825195 8.56055 0.862793 8.43164 0.937988C8.30273 1.01318 8.1792 1.1403 8.06104 1.31934L4.03271 7.6626L2.00244 5.19189C1.80192 4.95557 1.55127 4.8374 1.25049 4.8374C0.974772 4.8374 0.745605 4.92692 0.562988 5.10596C0.383952 5.28141 0.294434 5.50342 0.294434 5.77197C0.294434 5.89372 0.314128 6.0083 0.353516 6.11572C0.396484 6.22314 0.469889 6.33594 0.57373 6.4541L3.18945 9.58008C3.41862 9.84505 3.70687 9.97754 4.0542 9.97754Z'
                    fill='#5ABE60'
                  />
                </svg>
              </span>
            )}
          </p>

          <p
            className={`password__rule ${oneLetter === "error" ? "password__rules--wrong" : ""} ${oneLetter === "success" ? "password__rules--pass" : ""}`}
          >
            {t("passRules.oneLetter")}
            {oneLetter === "success" && (
              <span>
                <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M4.0542 9.97754C4.4445 9.97754 4.73991 9.82894 4.94043 9.53174L9.65088 2.38281C9.72607 2.27181 9.77979 2.16439 9.81201 2.06055C9.84424 1.95312 9.86035 1.85107 9.86035 1.75439C9.86035 1.48942 9.76904 1.26921 9.58643 1.09375C9.40739 0.914714 9.18001 0.825195 8.9043 0.825195C8.7181 0.825195 8.56055 0.862793 8.43164 0.937988C8.30273 1.01318 8.1792 1.1403 8.06104 1.31934L4.03271 7.6626L2.00244 5.19189C1.80192 4.95557 1.55127 4.8374 1.25049 4.8374C0.974772 4.8374 0.745605 4.92692 0.562988 5.10596C0.383952 5.28141 0.294434 5.50342 0.294434 5.77197C0.294434 5.89372 0.314128 6.0083 0.353516 6.11572C0.396484 6.22314 0.469889 6.33594 0.57373 6.4541L3.18945 9.58008C3.41862 9.84505 3.70687 9.97754 4.0542 9.97754Z'
                    fill='#5ABE60'
                  />
                </svg>
              </span>
            )}
          </p>
          <p
            className={`password__rule ${oneNumber === "error" ? "password__rules--wrong" : ""} ${oneNumber === "success" ? "password__rules--pass" : ""}`}
          >
            {t("passRules.oneNumber")}
            {oneNumber === "success" && (
              <span>
                <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M4.0542 9.97754C4.4445 9.97754 4.73991 9.82894 4.94043 9.53174L9.65088 2.38281C9.72607 2.27181 9.77979 2.16439 9.81201 2.06055C9.84424 1.95312 9.86035 1.85107 9.86035 1.75439C9.86035 1.48942 9.76904 1.26921 9.58643 1.09375C9.40739 0.914714 9.18001 0.825195 8.9043 0.825195C8.7181 0.825195 8.56055 0.862793 8.43164 0.937988C8.30273 1.01318 8.1792 1.1403 8.06104 1.31934L4.03271 7.6626L2.00244 5.19189C1.80192 4.95557 1.55127 4.8374 1.25049 4.8374C0.974772 4.8374 0.745605 4.92692 0.562988 5.10596C0.383952 5.28141 0.294434 5.50342 0.294434 5.77197C0.294434 5.89372 0.314128 6.0083 0.353516 6.11572C0.396484 6.22314 0.469889 6.33594 0.57373 6.4541L3.18945 9.58008C3.41862 9.84505 3.70687 9.97754 4.0542 9.97754Z'
                    fill='#5ABE60'
                  />
                </svg>
              </span>
            )}
          </p>
        </div>
        <div className={"recaptcha"}>
          <ReCAPTCHA
            ref={recaptchaRef}
            size='normal'
            sitekey={"6LcUCjkhAAAAAP8cEggM53HjS07LuUr8AIKeQadf"}
            onChange={onReCAPTCHAChange}
          />
        </div>
        <div className={"sign-form__remember sign-up-form__remember"}>
          <label className={"remember__label"}>
            {t("agreeWithPolicy")}
            <input {...register("_remember_me", { value: false })} type={"checkbox"} className={"remember__checkbox"} />
            <span className={"remember__checkmark"} />
          </label>
        </div>
        <Link className={"login__link"} to={"/privacy-policy"}>
          {t("privacyAndPolicy")}
        </Link>
        <button disabled={confirmLoading} className={"sign-form__btn register-form__btn"}>
          {t("registration")}
        </button>
        {error.length > 0 && <p className={"upload-form__error"}>{error}</p>}
      </form>
      <ConfirmCode data={data} confirm={confirm} hideHandler={hideHandler} />
      <Ouath create={true} />
      <p className={"sign-form__haveNoAcc"}>{t("haveAccount")}</p>
      <Link to={`/login`} className={"sign-form__haveNoAcc register"}>
        {t("enter")}
      </Link>
    </>
  );
};

export default SignUpForm;
