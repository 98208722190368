import { Action, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { ICounter, ICounterState, IFile } from "../../interfaces/counters/counters";
import { getCounterFiles, getProjectCounters } from "../../api/counters/counters";

const initialState: ICounterState = {
  counters: {
    entity: {
      current_page: 0,
      items: [],
      items_at_page: 0,
      pages_count: 0
    },
    loaded: false,
    errors: {
      isError: false,
      message: ""
    }
  },
  files: {
    entity: {
      current_page: 0,
      items: [],
      items_at_page: 0,
      pages_count: 0
    },
    page: 1,
    confirmLoading: false,
    count: 25,
    id: null,
    loaded: false,
    errors: {
      isError: false,
      message: ""
    }
  }
};

export const counterSelector = (state: { counters: ICounterState }) => state.counters;

const counterSlice = createSlice({
  name: "counters",
  initialState,
  reducers: {
    getCounters: (state, action: PayloadAction<any>) => {
      state.counters.entity = action.payload ? action.payload : [];
      state.counters.loaded = true;
      state.counters.errors.isError = false;
    },
    countersError: (state, action: PayloadAction<string>) => {
      state.counters.loaded = true;
      state.counters.errors.isError = true;
      state.counters.errors.message = action.payload;
    },
    getFiles: (state, action: PayloadAction<any>) => {
      state.files.entity = action.payload;
      state.files.loaded = true;
      state.files.errors.isError = false;
    },
    filesError: (state, action: PayloadAction<string>) => {
      state.files.loaded = true;
      state.files.errors.isError = true;
      state.files.errors.message = action.payload;
    },
    resetProject: state => {
      state.counters.entity.items = [];
      state.counters.loaded = false;
    },
    setId: (state, action: PayloadAction<number>) => {
      state.files.id = action.payload;
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.files.count = action.payload;
    },
    setLoad: (state, action: PayloadAction<boolean>) => {
      state.files.confirmLoading = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.files.page = action.payload;
    }
  }
});

export const { countersError, filesError, getCounters, getFiles, resetProject, setId, setCount, setLoad, setPage } =
  counterSlice.actions;

// Async Thunk
export const getProjectCountersThunk = (data: string): ThunkAction<void, ICounterState, unknown, Action<string>> => {
  return async dispatch => {
    try {
      const counters: ICounter[] | undefined = await getProjectCounters(data).then(response => {
        if (response && response.data) {
          return response.data;
        }
      });
      dispatch(getCounters(counters));
    } catch (e: any) {
      dispatch(countersError(e.response.data.message));
    }
  };
};
export const getCounterFilesThunk = (
  data: string,
  page?: string,
  count?: string,
  ignore?: string,
  sortByIsCrossed?: string
): ThunkAction<void, ICounterState, unknown, Action<string>> => {
  return async dispatch => {
    dispatch(setLoad(true));
    try {
      const files: IFile[] | undefined = await getCounterFiles(data, page, count, ignore, sortByIsCrossed).then(
        response => {
          if (response && response.data) {
            dispatch(setLoad(false));
            return response.data;
          }
        }
      );
      dispatch(getFiles(files));
    } catch (e: any) {
      dispatch(setLoad(false));
      dispatch(filesError(e.response.data.message));
    }
  };
};

export default counterSlice.reducer;
