import { Action, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { ITabs, ITabsState } from "../../interfaces/tabs";

const initialState: ITabsState = {
  tabs: {
    counters: true,
    errors: false,
    intersections: false
  }
};

export const tabsSelector = (state: { tabs: ITabsState }) => state.tabs;

const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    setCounters: state => {
      state.tabs.counters = true;
      state.tabs.errors = false;
      state.tabs.intersections = false;
    },
    setErrors: state => {
      state.tabs.counters = false;
      state.tabs.errors = true;
      state.tabs.intersections = false;
    },
    setIntersection: state => {
      state.tabs.counters = false;
      state.tabs.errors = false;
      state.tabs.intersections = true;
    }
  }
});

export const { setCounters, setIntersection, setErrors } = tabsSlice.actions;

export default tabsSlice.reducer;
