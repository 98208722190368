import React from "react";
import { Link } from "react-router-dom";
import { setDocumentTitle } from "../../utils/setDocumentTitle/setDocumentTitle";

interface props {}

const HasNoAccess: React.FC<props> = () => {
  setDocumentTitle(`Access Denied`);
  return (
    <div className={"not-found"}>
      <div>
        <div className={"not-found__title"}>Access Denied: 403</div>
        <div className={"not-found__text"}>Sorry, you do not have permissions to view this page</div>
        <Link to={"/projects"} className={"not-found__btn"}>
          Go to dashboard
        </Link>
      </div>
      <p className={"error__links"}>
        For help visit{" "}
        <a href={`https://apprefactoring.com/`} target={"_blank"}>
          apprefactoring.com
        </a>{" "}
        or contact <a href={"mailto:support@apprefactoring.com"}>support@apprefactoring.com</a>
      </p>
    </div>
  );
};

export default HasNoAccess;
