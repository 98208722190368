import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import user from "../../../assets/help/user.png";
import user1 from "../../../assets/help/user1.png";
import user2 from "../../../assets/help/user2.png";
import user3 from "../../../assets/help/user3.png";
import user4 from "../../../assets/help/user4.png";
import user5 from "../../../assets/help/user5.png";
import user6 from "../../../assets/help/user6.png";
import user7 from "../../../assets/help/user7.png";

interface props {}

const User: React.FC<props> = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const supportGuide = [
    {
      img: user4,
      text: t("user.support.first")
    },
    {
      img: user5,
      text: t("user.support.second")
    },
    {
      img: user6,
      text: t("user.support.third"),
      secondText: t("user.support.fourth")
    },
    {
      img: user7,
      text: t("user.support.fifth")
    }
  ];
  return (
    <section className={"account help"}>
      <div onClick={() => setVisible(!visible)} className={`help__btn ${visible ? "help__btn--hide" : ""}`}>
        <svg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1.45481 10.1816C1.05315 10.1816 0.727539 9.85603 0.727539 9.45437C0.727539 9.05271 1.05315 8.7271 1.45481 8.7271H14.5457C14.9474 8.7271 15.273 9.05271 15.273 9.45437C15.273 9.85603 14.9474 10.1816 14.5457 10.1816H1.45481ZM1.2933 7.27246C0.836287 7.27246 0.618803 6.70991 0.956968 6.40249L7.32733 0.611254C7.70875 0.264507 8.29125 0.264508 8.67267 0.611255L15.043 6.40249C15.3812 6.70991 15.1637 7.27246 14.7067 7.27246H1.2933Z'
            fill='#DDDDDD'
          />
        </svg>
      </div>
      <h2 onClick={() => setVisible(!visible)} className={"account__title "}>
        {t("user.title")}
      </h2>
      <div className={"account__info help__info"}>
        <div className={"account__name"}>
          <h4 onClick={() => setVisible(!visible)} className={"account__title help__title"}>
            {t("user.settings")}
          </h4>
          {visible && (
            <>
              <ul className={"help__list"}>
                <li className={"help__item"}>
                  <h4 className={"help__item--title"}>{t("user.changePersonal.title")}</h4>
                  <div className={"help__image"}>
                    <img src={user} alt={"user"} />
                  </div>
                  <p className={"help__item--text"}>{t("user.changePersonal.text")}</p>
                  <ul className={"help__sub-list"}>
                    <li className={"help__sub-list--item"}>{t("user.changePersonal.first")}</li>
                    <li className={"help__sub-list--item"}>{t("user.changePersonal.second")}</li>
                  </ul>
                  <p className={"help__item--text"}>{t("user.changePersonal.third")}</p>
                </li>
                <li className={"help__item"}>
                  <div className={"help__image"}>
                    <img src={user1} alt={"guide"} />
                  </div>
                  <div className={"help__image"}>
                    <img src={user2} alt={"guide"} />
                  </div>
                  <p className={"help__item--text"}>{t("user.changePersonal.secondText")}</p>
                  <p className={"help__item--text"}>{t("user.changePersonal.fourth")}</p>
                  <ul className={"help__sub-list"}>
                    <li className={"help__sub-list--item"}>{t("user.changePersonal.fifth")}</li>
                    <li className={"help__sub-list--item"}>{t("user.changePersonal.sixth")}</li>
                  </ul>
                  <p className={"help__item--text"}>{t("user.changePersonal.seventh")}</p>
                  <ul className={"help__sub-list"}>
                    <li className={"help__sub-list--item"}>{t("user.changePersonal.eight")}</li>
                    <li className={"help__sub-list--item"}>{t("user.changePersonal.ninth")}</li>
                    <li className={"help__sub-list--item"}>{t("user.changePersonal.ten")}</li>
                  </ul>
                </li>
                <li className={"help__item"}>
                  <h4 className={"help__item--title"}>{t("notifications")}</h4>
                  <div className={"help__image"}>
                    <img src={user3} alt={"guide"} />
                  </div>
                  <p className={"help__item--text"}>{t("user.notifications.first")}</p>
                  <ul className={"help__sub-list"}>
                    <li className={"help__sub-list--item"}>{t("email")}</li>
                    <li className={"help__sub-list--item"}>Slack</li>
                    <li className={"help__sub-list--item"}>Telegram</li>
                  </ul>
                  <p className={"help__item--text"}>{t("user.notifications.second")}</p>
                  <ul className={"help__sub-list"}>
                    <li className={"help__sub-list--item"}>{t("user.notifications.third")}</li>
                    <li className={"help__sub-list--item"}>{t("user.notifications.fourth")}</li>
                  </ul>
                  <p className={"help__item--text"}>{t("user.notifications.fifth")}</p>
                  <ul className={"help__sub-list"}>
                    <li className={"help__sub-list--item"}>{t("user.notifications.sixth")}</li>
                    <li className={"help__sub-list--item"}>{t("user.notifications.seventh")}</li>
                  </ul>
                  <p className={"help__item--text"}>{t("user.notifications.eight")}</p>
                  <p className={"help__item--text"}>{t("user.notifications.ninth")}</p>
                  <ul className={"help__sub-list"}>
                    <li className={"help__sub-list--item"}>{t("user.notifications.ten")}</li>
                    <li className={"help__sub-list--item"}>{t("user.notifications.eleventh")}</li>
                    <li className={"help__sub-list--item"}>{t("user.notifications.twelves")}</li>
                  </ul>
                </li>
                <li className={"help__item"}>
                  <h4 className={"help__item--title"}>{t("support")}</h4>
                  <p className={"help__item--text"}>{t("user.support.text")}</p>
                </li>
                {supportGuide.map(({ img, text, secondText }) => (
                  <li className={"help__item"} key={text}>
                    <div className={"help__image"}>
                      <img src={img} alt={"guide"} />
                    </div>
                    <p className={"help__item--text"}>{text}</p>
                    {secondText && <p className={"help__item--text"}>{secondText}</p>}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default User;
