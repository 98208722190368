import React, { useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "../onBoarding.css";
import onboardSlide from "../../../../../assets/helpGuide/onboard0.svg";
import onboardSlide2 from "../../../../../assets/helpGuide/onboard.svg";
import onboardSlide3 from "../../../../../assets/helpGuide/onboard-1.svg";
import onboardSlide4 from "../../../../../assets/helpGuide/onboard-2.svg";
import onboardSlide5 from "../../../../../assets/helpGuide/onboard-3.svg";
import onboardSlide6 from "../../../../../assets/helpGuide/onboard-4.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface props {}

const OnboardSlider: React.FC<props> = () => {
  const slides = [onboardSlide, onboardSlide2, onboardSlide3, onboardSlide4, onboardSlide5, onboardSlide6];
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const skipOnBoarding = () => navigate("/projects");
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      perView: 1,
      spacing: 10
    },
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    }
  });
  return (
    <div className={"onboard__slider--wrap"}>
      <div ref={sliderRef} className={"keen-slider"}>
        {slides.map(slide => (
          <div key={slide} className={"onboard__slider--item keen-slider__slide"}>
            <img src={slide} />
          </div>
        ))}
      </div>
      <div className='dots'>
        {slides.map((item, idx) => {
          return (
            <button
              key={idx}
              onClick={() => {
                instanceRef.current?.moveToIdx(idx);
              }}
              className={"dot" + " " + (currentSlide === idx ? "dot-active" : "")}
            />
          );
        })}
      </div>
      <div className={"onboard__btns"}>
        <button onClick={skipOnBoarding} className={"onboard__skip"}>
          {t("skip")}
        </button>
        <button onClick={() => instanceRef.current?.next()} className={"onboard__next"}>
          {t("next")}
        </button>
      </div>
    </div>
  );
};

export default OnboardSlider;
