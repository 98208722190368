import { Action, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { IError, IErrorState } from "../../interfaces/projects/projectErrors";
import { getCategoriesApi } from "../../api/categories/categories";
import { getErrorsApi } from "../../api/project/errors";

const initialState: IErrorState = {
  errors: {
    entity: {
      current_page: 0,
      items: [],
      items_at_page: 0,
      pages_count: 0
    },
    loaded: false,
    error: {
      isError: false,
      message: ""
    }
  }
};

export const errorsSelector = (state: { errors: IErrorState }) => state.errors;

const errorsSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    getErrors: (state, action: PayloadAction<any>) => {
      state.errors.loaded = true;
      state.errors.entity = action.payload;
      state.errors.error.isError = false;
    },
    errorsWrongs: (state, action: PayloadAction<string>) => {
      state.errors.loaded = true;
      state.errors.error.isError = true;
      state.errors.error.message = action.payload;
    },
    resetErrors: state => {
      state.errors.entity.items = [];
      state.errors.loaded = false;
    }
  }
});

export const { errorsWrongs, getErrors, resetErrors } = errorsSlice.actions;

export const getErrorsThunk = (
  data: string,
  page?: string
): ThunkAction<void, IErrorState, unknown, Action<string>> => {
  return async dispatch => {
    try {
      const errors: IError[] | undefined = await getErrorsApi(data, page).then(response => {
        if (response && response.data) {
          return response.data;
        }
      });
      dispatch(getErrors(errors));
    } catch (e: any) {
      dispatch(errorsWrongs(e.response.data.detail));
    }
  };
};

export default errorsSlice.reducer;
