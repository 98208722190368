import React, { useEffect, useState } from "react";
import "./team-roles.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getTeamRolesThunk, teamRolesSelector } from "../../../../store/slices/team-roles-slice";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../shared/loader/loader";
import { editTeamRolesApi } from "../../../../api/team-roles/team-roles";
import TeamRole from "./team-role";
import { useTranslation } from "react-i18next";
import { userSelector } from "../../../../store/slices/user-slice";
import { teamSelector } from "../../../../store/slices/team-slice";

interface props {}

const TeamRoles: React.FC<props> = () => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { team_roles } = useSelector(teamRolesSelector);
  const [error, setError] = useState<string>("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector(userSelector);
  const { team } = useSelector(teamSelector);
  const { teamId } = useParams();
  useEffect(() => {
    if (teamId) {
      dispatch(getTeamRolesThunk(teamId));
    }
  }, []);

  if (!team_roles.loaded) return <Loader />;

  const onFinish = (e: any) => {
    if (teamId) {
      editTeamRolesApi(teamId, e)
        .then(response => {
          if (response && response.data) {
            dispatch(getTeamRolesThunk(teamId));
          }
        })
        .catch(e => {
          if (e.response && e.response.status === 500) {
            navigate("/500");
          }
          if (e.response && e.response.status === 403) {
            navigate("/403");
          }
          if (e.response && e.response.status === 404) {
            navigate("/500");
          }
          if (e.response && e.response.data) {
            setError(e.response.data.message);
          }
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onFinish)} className={"intersection-range"}>
      <h4 className={"intersection-range__title"}>{t("permissions")}</h4>
      <div className={"intersection-range__inputs"}>
        <div className={"intersection-range__bar-wrapper"}></div>
        {team.entity.owner.id === user.entity.id
          ? [...team_roles.entity]
              .sort((a, b) => a.priority - b.priority)
              .map(({ role, id, permissions }) => (
                <div key={id} className={"intersection-range__input"} style={{ marginTop: 0 }}>
                  <p className={"intersection-range__input--label"}>{t(role)}</p>
                  <div className={"member-add__form--permissions team-roles__permissions"}>
                    {Object.entries(permissions).map(([key, value]) => {
                      return <TeamRole k={key} key={key} value={value} id={id} register={register} />;
                    })}
                  </div>
                </div>
              ))
          : team_roles.entity
              ?.filter(item => item.role !== "admin")
              .sort((a, b) => a.priority - b.priority)
              .map(({ role, id, permissions }) => (
                <div key={id} className={"intersection-range__input"} style={{ marginTop: 0 }}>
                  <p className={"intersection-range__input--label"}>{t(role)}</p>
                  <div className={"member-add__form--permissions team-roles__permissions"}>
                    {Object.entries(permissions).map(([key, value]) => {
                      return <TeamRole k={key} key={key} value={value} id={id} register={register} />;
                    })}
                  </div>
                </div>
              ))}
        {error.length > 0 && <p className={"upload-form__error"}>{error}</p>}
        <p className={"intersection-range__text"}>{t("permissionsText")}</p>
        <p className={"intersection-range__text"}>{t("permissionsDescription")}</p>
      </div>
      <div className={"intersection-range__btn"}>
        <button className={"submit-btn"}>{t("saveChanges")}</button>
      </div>
    </form>
  );
};

export default TeamRoles;
