import { Action, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { ITeamRole, ITeamRolesState } from "../../interfaces/team-roles/team-roles";
import { getTeamRolesApi } from "../../api/team-roles/team-roles";

const initialState: ITeamRolesState = {
  team_roles: {
    entity: [],
    loaded: false,
    errors: {
      isError: false,
      message: ""
    }
  }
};

export const teamRolesSelector = (state: { team_roles: ITeamRolesState }) => state.team_roles;

const teamRolesSlice = createSlice({
  name: "team_roles",
  initialState,
  reducers: {
    getTeamRoles: (state, action: PayloadAction<ITeamRole[]>) => {
      state.team_roles.entity = action.payload;
      state.team_roles.loaded = true;
      state.team_roles.errors.isError = false;
    },
    teamErrors: (state, action: PayloadAction<string>) => {
      state.team_roles.loaded = true;
      state.team_roles.errors.isError = true;
      state.team_roles.errors.message = action.payload;
    },
    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.team_roles.loaded = action.payload;
    }
  }
});

export const { getTeamRoles, teamErrors, setLoaded } = teamRolesSlice.actions;

export const getTeamRolesThunk = (teamID: string): ThunkAction<void, ITeamRolesState, unknown, Action<string>> => {
  return async dispatch => {
    try {
      dispatch(setLoaded(false));
      const teamRoles: ITeamRole[] = await getTeamRolesApi(teamID).then(response => {
        if (response && response.data) {
          return response.data.roles;
        }
      });
      dispatch(getTeamRoles(teamRoles));
      dispatch(setLoaded(true));
    } catch (e: any) {
      const errorMessage = e.response.data.detail || e.response.data.message;
      dispatch(teamErrors(errorMessage));
      dispatch(setLoaded(true));
    }
  };
};

export default teamRolesSlice.reducer;
