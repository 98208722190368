import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useWindowSize } from "../../../hooks/useWindowParams";
import { sidebarSelector } from "../../../store/slices/sidebar-slice";
import { useTranslation } from "react-i18next";

interface props {
  hideSidebarHandler: () => void;
}

const Projects: React.FC<props> = ({ hideSidebarHandler }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { sidebar } = useSelector(sidebarSelector);
  const hide = () => {
    if (width && width <= 768) {
      hideSidebarHandler();
    }
  };

  return (
    <>
      <Link onClick={hide} to={"/projects"} className={"aside__item--wrapper"}>
        <div className={"aside__item--icon"}>
          <svg width='19' height='18' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M3.49638 0H1.12856C0.528555 0 0.0636244 0.52469 0.135823 1.12033L0.424255 3.49989H3.92061L3.50366 0.0600577L3.49638 0ZM0.545467 4.49989L1.34795 11.1203C1.4088 11.6224 1.83493 12 2.34068 12H4.95093L4.04182 4.49989H0.545467ZM5.95824 12H14.8715C15.4715 12 15.9364 11.4753 15.8642 10.8797L15.0909 4.49989H5.04914L5.95094 11.9397L5.95824 12ZM14.9697 3.49989H4.92793L4.5037 0H13.6594C14.1651 0 14.5913 0.377598 14.6521 0.879669L14.9697 3.49989Z'
              fill='#5ABE60'
            />
          </svg>
        </div>
        <div className={`aside__item--info ${sidebar.isVisible ? "" : "aside__item--info-hidden"}`}>
          <h3 className={"aside__item--title"}>{t("projects")}</h3>
        </div>
      </Link>
    </>
  );
};

export default Projects;
