import React, { useEffect, useState } from "react";
import { getHashesApi } from "../../../../../../../api/hashes/hashes";
import { useDispatch, useSelector } from "react-redux";
import { getHashes, hashSelector } from "../../../../../../../store/slices/hash-slice";
import Loader from "../../../../../../../shared/loader/loader";
import { useTranslation } from "react-i18next";

interface props {
  page: number;
  ignoredHandler: (value: boolean) => void;
  ignore: boolean;
}

const HashSort: React.FC<props> = ({ page, ignore, ignoredHandler }) => {
  const { hashes } = useSelector(hashSelector);
  const { t } = useTranslation();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (hashes.id) {
      setConfirmLoading(true);
      getHashesApi(hashes.id.toString(), `?page=${page}`, `&ignored=${ignore}`, `&count=${hashes.count}`)
        .then(response => {
          if (response && response.data) {
            dispatch(getHashes(response.data));
          }
        })
        .catch(e => {})
        .finally(() => setConfirmLoading(false));
    }
  }, [ignore]);
  return (
    <>
      {confirmLoading && <Loader />}
      <div className={"hash__filters"}>
        <div onClick={() => ignoredHandler(true)} className={`hash__filter ${ignore ? "hash__filter--active" : ""}`}>
          {t("ignorant")}
        </div>
        <div onClick={() => ignoredHandler(false)} className={`hash__filter ${ignore ? "" : "hash__filter--active"}`}>
          {t("unIgnore")}
        </div>
      </div>
    </>
  );
};

export default HashSort;
