import React, { useState } from "react";
import Counters from "./counters";
import CountersHashForm from "./components/counters-hash-form";

interface props {}

const Index: React.FC<props> = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const showHashes = () => setVisible(true);
  const hideHashes = () => setVisible(false);
  return (
    <div className={"analysis-data"}>
      <Counters showHashes={showHashes} hideHashes={hideHashes} />
      {visible && <CountersHashForm hideHashes={hideHashes} />}
    </div>
  );
};

export default Index;
