import React from "react";
import "./privacy-policy.css";

interface props {}

const PrivacyPolicy: React.FC<props> = () => {
  return (
    <section className={"account privacy"}>
      <h2 className={"account__title"}>Privacy Policy</h2>
      <div className={"account__info"}>
        <p className={"privacy__text"}>
          Lua Tri built the AppRefactoring as a Free app. This SERVICE is provided by Lua Tri at no cost and is intended
          for use as is. This page is used to inform visitors regarding my policies with the collection, use, and
          disclosure of Personal Information if anyone decided to use my Service.
        </p>
        <p className={"privacy__text"}>
          If you choose to use my Service, then you agree to the collection and use of information in relation to this
          policy. The Personal Information that I collect is used for providing and improving the Service. I will not
          use or share your information with anyone except as described in this Privacy Policy. The terms used in this
          Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at AppRefactoring
          unless otherwise defined in this Privacy Policy.
        </p>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
