import React, { ReactElement, useEffect } from "react";
import "./aside.css";
import { useLocation, useParams } from "react-router-dom";
import Projects from "./components/projects";
import Teams from "./components/teams/teams";
import Settings from "./components/settings";
import Support from "./components/support";
import { useWindowSize } from "../../hooks/useWindowParams";
import { useDispatch, useSelector } from "react-redux";
import { hideSidebar, sidebarSelector, visibleToggle } from "../../store/slices/sidebar-slice";
import Help from "./components/Help";
import close from "../../assets/icons/toggler.svg";
import show from "../../assets/icons/openAside.svg";
import { useTranslation } from "react-i18next";

interface props {}

interface INavigation {
  id: number;
  element: ReactElement;
  path?: string;
  childPath?: string;
}

const Aside: React.FC<props> = () => {
  const { width } = useWindowSize();
  const { sidebar } = useSelector(sidebarSelector);
  const { pathname } = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const hideSidebarHandler = () => {
    dispatch(hideSidebar(false));
    document.body.style.overflow = "visible";
  };
  let touchstartX = 0;
  let touchendX = 0;
  useEffect(() => {
    function swipeHandler() {
      if (touchendX < touchstartX) hideSidebarHandler();
    }

    if (width && width <= 991) {
      dispatch(hideSidebar(false));
    }
    document.addEventListener("touchstart", ev => {
      touchstartX = ev.changedTouches[0].screenX;
    });
    document.addEventListener("touchend", ev => {
      touchendX = ev.changedTouches[0].screenX;
      swipeHandler();
    });

    return () => {
      document.removeEventListener("touchstart", ev => {});
      document.removeEventListener("touchend", ev => {});
    };
  }, [width, touchstartX, touchendX]);
  useEffect(() => {
    if (!sidebar.isVisible) {
      document.body.style.overflow = "visible";
    }
  }, [sidebar.isVisible]);
  const height = window.outerHeight;
  const items: INavigation[] = [
    {
      id: 1,
      element: <Projects hideSidebarHandler={hideSidebarHandler} />,
      path: "/projects"
    },
    {
      id: 10,
      element: <Teams hideSidebarHandler={hideSidebarHandler} />
    },
    {
      id: 2,
      element: <Settings hideSidebarHandler={hideSidebarHandler} />,
      path: "/settings"
    },
    {
      id: 3,
      element: <Support hideSidebarHandler={hideSidebarHandler} />,
      path: "/support",
      childPath: `/support/${id}`
    },
    {
      id: 4,
      element: <Help hideSidebarHandler={hideSidebarHandler} />,
      path: "/help"
    }
  ];

  return (
    <aside
      className={`aside ${sidebar.isVisible ? "aside--show" : ""} ${sidebar.isVisible ? "" : "aside__wrap"}`}
      style={{ height: `(${height} + 100vh)` }}
    >
      <ul className={"aside__list"}>
        {items.map(({ id, element, path, childPath }) => (
          <li
            key={id}
            className={`aside__item ${id !== 10 ? "aside__item--hover" : ""} ${(path && path === pathname) || (childPath && childPath === pathname) ? "aside__item--active" : ""}`}
          >
            {element}
          </li>
        ))}
      </ul>
      <div
        onClick={() => {
          dispatch(visibleToggle());
        }}
        className={`aside__toggler ${sidebar.isVisible ? `aside__toggler--hide` : ``}`}
      >
        <img src={sidebar.isVisible ? close : show} alt={"toggler"} />
        <span
          className={`aside__item--info ${sidebar.isVisible ? "" : "aside__item--info-hidden"}`}
          style={{ fontSize: "12px" }}
        >
          {t("hideMenu")}
        </span>
      </div>
    </aside>
  );
};

export default Aside;
