import { Action, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { ISidebarState } from "../../interfaces/sidebar/sidebar";

const initialState: ISidebarState = {
  sidebar: {
    isVisible: true
  }
};

export const sidebarSelector = (state: { sidebar: ISidebarState }) => state.sidebar;

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: initialState,
  reducers: {
    visibleToggle: state => {
      state.sidebar.isVisible = !state.sidebar.isVisible;
    },
    hideSidebar: (state, action: PayloadAction<boolean>) => {
      state.sidebar.isVisible = action.payload;
    }
  }
});

export const { visibleToggle, hideSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
