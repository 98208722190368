import React, { useEffect, useState } from "react";
import "./ticket-list.css";
import TicketItem from "../ticket-item/ticket-item";
import { useDispatch, useSelector } from "react-redux";
import { getTickets, getTicketsThunk, resetTickets, ticketSelector } from "../../../../store/slices/tickets-slice";
import Loader from "../../../../shared/loader/loader";
import { getTicketsApi } from "../../../../api/tickets/tickets";
import { Pagination } from "../../../../shared/pagination";
import TicketFilters from "../ticket-filters/ticket-filters";
import { setPage, ticketFilter } from "../../../../store/slices/ticket-filter-slice";
import { useTranslation } from "react-i18next";

interface props {}

const TicketList: React.FC<props> = () => {
  const { tickets } = useSelector(ticketSelector);
  const dispatch = useDispatch();
  const { ticketFilters } = useSelector(ticketFilter);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const handlePageChange = (count: number) => {
    setIsFirstLoad(false);
    dispatch(setPage(count));
  };
  useEffect(() => {
    dispatch(getTicketsThunk(`?page=${ticketFilters.page}`));
    return () => {
      dispatch(resetTickets());
    };
  }, []);
  useEffect(() => {
    if (!isFirstLoad) {
      setConfirmLoading(true);
      getTicketsApi(
        `?page=${ticketFilters.page}`,
        ticketFilters.sort.sortBy.length ? `&sortBy=${ticketFilters.sort.sortBy}` : "",
        ticketFilters.sort.sortValue.length ? `&sortValue=${ticketFilters.sort.sortValue}` : "",
        ticketFilters.filters.filterBy.length ? `&filterBy=${ticketFilters.filters.filterBy}` : "",
        ticketFilters.filters.filterValue.length ? `&filterValue=${ticketFilters.filters.filterValue}` : ""
      )
        .then(response => {
          if (response && response.data) {
            dispatch(getTickets(response.data));
          }
        })
        .finally(() => setConfirmLoading(false));
    }
    return () => {
      setIsFirstLoad(true);
    };
  }, [ticketFilters.page]);

  if (!tickets.loaded) {
    return <Loader />;
  }

  return (
    <>
      {confirmLoading && <Loader />}
      <h2 className={"ticket-list__title"}>{t("tickets")}</h2>
      <TicketFilters />
      <div className={"ticket-list"}>
        {tickets.entity.items?.length
          ? tickets.entity.items.map(ticket => <TicketItem key={ticket.id} ticket={ticket} />)
          : null}
      </div>
      {tickets.entity.items?.length ? (
        <Pagination
          current={ticketFilters.page}
          onChange={handlePageChange}
          last={tickets.entity.pages_count}
          hasNext={tickets.entity.pages_count > ticketFilters.page}
          disabled={false}
          isWhiteDots={true}
        />
      ) : null}
    </>
  );
};

export default TicketList;
