import React from "react";
import { useNavigate } from "react-router-dom";
import { setDocumentTitle } from "../../utils/setDocumentTitle/setDocumentTitle";

interface props {}

const ServerError: React.FC<props> = () => {
  const navigate = useNavigate();
  setDocumentTitle(`Server Error`);
  return (
    <div className={"not-found"}>
      <div>
        <div className={"not-found__title"}>Server error: 500</div>
        <div className={"not-found__text"}>Internal Server Error</div>
        <div className={"not-found__text"}>Something went wrong please try again later</div>
        <div onClick={() => navigate(-1)} className={"not-found__btn"}>
          Try again
        </div>
      </div>
      <p className={"error__links"}>
        For help visit{" "}
        <a href={`https://apprefactoring.com/`} target={"_blank"}>
          apprefactoring.com
        </a>{" "}
        or contact <a href={"mailto:support@apprefactoring.com"}>support@apprefactoring.com</a>
      </p>
    </div>
  );
};

export default ServerError;
