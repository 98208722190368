import React, { useEffect, useState } from "react";
import "./notifications.css";
import Notification from "./components/notification";
import { useDispatch, useSelector } from "react-redux";
import { auth, userSelector } from "../../../../store/slices/user-slice";
import Loader from "../../../../shared/loader/loader";
import { deleteNotification, makeAsReadNotification } from "../../../../api/notifications/notifications";
import { getUserInfo } from "../../../../api/user/user";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface props {}

const Notifications: React.FC<props> = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const { user } = useSelector(userSelector);
  const dispatch = useDispatch();
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const confirmHandle = () => setIsConfirm(true);
  const confirmDisable = () => setIsConfirm(false);
  const navigate = useNavigate();
  const showHandler = () => {
    setVisible(true);
    document.body.style.overflow = "hidden";
  };
  const hideHandler = () => {
    setVisible(false);
    document.body.style.overflow = "visible";
  };
  const { t } = useTranslation();
  const markAsReadHandler = () => {
    if (user.entity.notifications.length > 0) {
      return Promise.all(
        user.entity.notifications.map(item => {
          return makeAsReadNotification(item.id);
        })
      ).then(response => {
        if (response.length > 0) {
          getUserInfo()
            .then(response => {
              if (response && response.data) {
                dispatch(auth(response.data.user));
              }
            })
            .catch(e => {
              if (e.response.status === 403) {
                navigate("/403");
              }
              if (e.response.status === 500) {
                navigate("/500");
              }
            });
        }
      });
    }
  };
  const removeNotifications = () => {
    if (user.entity.notifications.length > 0) {
      return Promise.all(
        user.entity.notifications.map(item => {
          return deleteNotification(item.id);
        })
      )
        .then(response => {
          if (response.length > 0) {
            getUserInfo()
              .then(response => {
                if (response && response.data) {
                  dispatch(auth(response.data.user));
                }
              })
              .catch(e => {
                if (e.response.status === 403) {
                  navigate("/403");
                }
                if (e.response.status === 500) {
                  navigate("/500");
                }
              });
          }
        })
        .finally(() => {
          confirmDisable();
        });
    }
  };
  if (!user.loaded) return <Loader />;
  const copiedNotifications = [...user.entity.notifications];
  const notifications = copiedNotifications.sort((a, b) => (a.id > b.id ? -1 : 1));
  const filteredNotificationsLength =
    user.entity.notifications.length > 0 ? user.entity.notifications.filter(i => !i.isViewed).length : 0;
  return (
    <div className={"notifications"}>
      <div
        onClick={showHandler}
        className={filteredNotificationsLength > 0 ? "notifications__icon--active" : "notifications__icon"}
      >
        <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M12 15C12 16.6569 10.6569 18 9 18C7.34315 18 6 16.6569 6 15H12Z'
            fill={filteredNotificationsLength > 0 ? "#000000" : "#5ABE60"}
          />
          <path
            d='M11.25 14.25C11.25 14.8468 11.013 15.419 10.591 15.841C10.1691 16.263 9.59676 16.5 9.00002 16.5C8.40328 16.5 7.83099 16.263 7.40903 15.841C6.98707 15.419 6.75002 14.8468 6.75002 14.25M9.54077 3.75226L8.44127 3.75001C5.93327 3.74401 3.75602 5.78176 3.73877 8.25001V11.0925C3.73877 11.685 3.66377 12.2633 3.34052 12.756L3.12527 13.0845C2.79752 13.5825 3.15002 14.25 3.73877 14.25H14.2613C14.85 14.25 15.2018 13.5825 14.8748 13.0845L14.6595 12.756C14.337 12.2633 14.2613 11.6843 14.2613 11.0918V8.25076C14.2313 5.78176 12.0488 3.75826 9.54077 3.75226Z'
            stroke={filteredNotificationsLength > 0 ? "#000000" : "#5ABE60"}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M9 1.5C9.39782 1.5 9.77935 1.65804 10.0607 1.93934C10.342 2.22064 10.5 2.60218 10.5 3V3.75H7.5V3C7.5 2.60218 7.65804 2.22064 7.93934 1.93934C8.22064 1.65804 8.60218 1.5 9 1.5Z'
            stroke={filteredNotificationsLength > 0 ? "#000000" : "#5ABE60"}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>

        {filteredNotificationsLength > 0 && filteredNotificationsLength}
      </div>
      <div className={`member-add__wrapper ${visible ? "member-add__wrapper--show" : ""}`}>
        <div onClick={hideHandler} className={`member-add__backdrop ${visible ? "member-add__backdrop--show" : ""}`} />
        <div
          className={`member-add__form ${visible ? `member-add__form--show` : ""}`}
          style={{ background: "linear-gradient(180deg, #171717 0%, #3D3D3D 100%)" }}
        >
          <div className={"member-add__form--top"}>
            <h2 className={"member-add__form--title notifications__title"}>{t("notifications")}</h2>
            <div onClick={hideHandler} className={"notifications__close"}>
              <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M1.0625 11.8203C1.13542 11.8984 1.22396 11.9479 1.32812 11.9688C1.43229 11.9948 1.53385 11.9948 1.63281 11.9688C1.73177 11.9479 1.82292 11.8984 1.90625 11.8203L6.69531 7.02344L11.4922 11.8203C11.6068 11.9349 11.7474 11.9922 11.9141 11.9922C12.0807 11.9974 12.2214 11.9401 12.3359 11.8203C12.4505 11.7005 12.5078 11.5573 12.5078 11.3906C12.5078 11.224 12.4505 11.0859 12.3359 10.9766L7.53906 6.17969L12.3359 1.38281C12.4505 1.27344 12.5078 1.13542 12.5078 0.96875C12.513 0.796875 12.4557 0.653646 12.3359 0.539062C12.2214 0.424479 12.0807 0.367188 11.9141 0.367188C11.7474 0.367188 11.6068 0.424479 11.4922 0.539062L6.69531 5.33594L1.90625 0.539062C1.82292 0.466146 1.73177 0.416667 1.63281 0.390625C1.53385 0.364583 1.43229 0.364583 1.32812 0.390625C1.22396 0.411458 1.13542 0.460938 1.0625 0.539062C0.984375 0.617188 0.932292 0.708333 0.90625 0.8125C0.880208 0.911458 0.880208 1.01302 0.90625 1.11719C0.932292 1.22135 0.984375 1.3099 1.0625 1.38281L5.85156 6.17969L1.0625 10.9766C0.984375 11.0495 0.929688 11.138 0.898438 11.2422C0.872396 11.3464 0.872396 11.4505 0.898438 11.5547C0.924479 11.6589 0.979167 11.7474 1.0625 11.8203Z'
                  fill='#808080'
                />
              </svg>
            </div>
          </div>
          <div className={"notifications__read"}>
            {isConfirm ? (
              <>
                <div className={"notifications__as-read"}>{t("areYouSure")}</div>
                <div className={"notifications__clear"}>
                  <button onClick={confirmDisable}>{t("no")}</button>
                  <button onClick={removeNotifications}>{t("yes")}</button>
                </div>
              </>
            ) : (
              <>
                <div onClick={markAsReadHandler} className={"notifications__as-read"}>
                  {t("markAsRead")}
                </div>
                <button onClick={notifications.length ? confirmHandle : undefined} className={"notifications__clear"}>
                  {t("clearAll")}
                </button>
              </>
            )}
          </div>
          {notifications.length > 0 && notifications.map(item => <Notification notification={item} key={item.id} />)}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
