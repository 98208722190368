import { IPermission } from "../interfaces/members/members";

export interface IPriorityList {
  admin: number;
  moderator: number;
  project_access: number;
  viewer: number;
}

export const permissions = [
  {
    value: "canUploadProjects",
    title: "Uploading projects"
  },
  {
    value: "canDeleteProjects",
    title: "Deleting projects"
  },
  {
    value: "canAddParticipants",
    title: "Adding participants"
  },
  {
    value: "canDeleteParticipants",
    title: "Deleting participants"
  },
  {
    value: "canReindexProjects",
    title: "Reindexing projects"
  },
  {
    value: "canIgnoreHash",
    title: "Ignoring hash"
  }
];

export interface IRolesPermission {
  admin: IPermission;
  viewer: IPermission;
  project_access: IPermission;
  moderator: IPermission;
}

export const rolesPermissions: IRolesPermission = {
  admin: {
    canUploadProjects: true,
    canDeleteProjects: true,
    canAddParticipants: true,
    canDeleteParticipants: true,
    canReindexProjects: true,
    canIgnoreHash: true
  },
  viewer: {
    canUploadProjects: false,
    canDeleteProjects: false,
    canAddParticipants: false,
    canDeleteParticipants: false,
    canReindexProjects: false,
    canIgnoreHash: false
  },
  project_access: {
    canUploadProjects: true,
    canDeleteProjects: false,
    canAddParticipants: false,
    canDeleteParticipants: false,
    canReindexProjects: false,
    canIgnoreHash: false
  },
  moderator: {
    canUploadProjects: true,
    canDeleteProjects: true,
    canAddParticipants: false,
    canDeleteParticipants: false,
    canReindexProjects: true,
    canIgnoreHash: true
  }
};

export const priorityList: IPriorityList = {
  admin: 1,
  moderator: 2,
  project_access: 3,
  viewer: 4
};
