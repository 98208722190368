import axios, { AxiosResponse } from "axios";
import { HOST } from "../../constants/host";
import { IAuth, ILogin, IRegistration } from "../../interfaces/user/user";
import makeRequest from "../makeRequest";

export function userAuth(data: ILogin) {
  return axios({
    method: "POST",
    url: `${HOST}api/login_check`,
    data: data,
    headers: { "Content-Type": "application/json" }
  });
}

export function userRegistration(data: IRegistration) {
  return axios({
    method: "POST",
    url: `${HOST}api/users/`,
    data: data,
    headers: { "Content-Type": "application/json" }
  });
}

export function getUserInfo() {
  return makeRequest.get("api/user/");
}

export const changeUsername = (id: number, data: { username: string; password: string }) => {
  return makeRequest({
    method: "PATCH",
    data: data,
    url: `api/user/change_username`
  });
};
export const changeEmail = (id: number, data: { email: string }) => {
  return makeRequest({
    method: "PATCH",
    data: data,
    url: `api/user/change_email`
  });
};
export const changePassword = (id: number, data: { oldPassword: string; newPassword: string }) => {
  return makeRequest({
    method: "PATCH",
    data: data,
    url: `api/user/change_password`
  });
};

export const userNotification = (data: any) => {
  return makeRequest({
    method: "PATCH",
    data: data,
    url: `api/user/configure_notifications`
  });
};
export const resetPassword = (data: { email: string }) => {
  return makeRequest({
    method: "POST",
    url: `api/users/reset/password`,
    data: data
  });
};

export const confirmCode = (data: { email: string; code: string }) => {
  return makeRequest({
    method: "POST",
    url: "api/2fa/confirm",
    data: data
  });
};
