import React, { HTMLInputTypeAttribute, useEffect, useRef, useState } from "react";
import "./custom-file-select.css";
import GitLinkModal from "./git-link-modal";
import { useDispatch, useSelector } from "react-redux";
import { setFileName, uploadSelector } from "../../../../../../store/slices/upload-project-slice";
import { IDataSelects } from "../../upload-form";
import { useTranslation } from "react-i18next";
import DropboxModal from "../dropbox-modal";

interface props {
  isVisible: boolean;
  visibleHandler: (data: IDataSelects) => void;
  resetSuccess: () => void;
}

const CustomFileSelect: React.FC<props> = ({ isVisible, visibleHandler, resetSuccess }) => {
  const { upload } = useSelector(uploadSelector);
  const { t } = useTranslation();
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const toggleOptions = () => {
    resetSuccess();
    setIsOptionsOpen(!isOptionsOpen);
  };
  useEffect(() => {
    if (upload.fileName) {
      setIsOptionsOpen(false);
    }
  }, [upload.fileName]);
  const dispatch = useDispatch();
  const onChangeFile = (e: any) => {
    dispatch(setFileName(e.target.files[0]));
    setIsOptionsOpen(false);
  };
  const wrapperRef = useRef(null);
  const hideOptions = (e: any) => {
    if (wrapperRef.current) {
      // @ts-ignore
      if (!wrapperRef.current.contains(e.target)) {
        setIsOptionsOpen(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mouseup", hideOptions);
    return () => document.removeEventListener("mouseup", hideOptions);
  }, []);
  const onRemoveFile = (e: any) => {
    dispatch(setFileName(null));
  };
  return (
    <div ref={wrapperRef} className='wrapper'>
      <div className='custom-select__container'>
        {upload.fileName || upload.archiveUrl ? (
          <button type='button' onClick={toggleOptions} className={"custom-select__file"}>
            <div>
              {upload.fileName && typeof upload.fileName.name === "string"
                ? `${upload.fileName.name.slice(0, 25)}...`
                : upload.fileName
                  ? upload.fileName.name
                  : ""}
              {upload.archiveUrl && upload.archiveUrl.length > 0 && `${upload.archiveUrl.slice(0, 25)}...`}
            </div>
            <svg
              onClick={onRemoveFile}
              width='13'
              height='13'
              viewBox='0 0 13 13'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1.0625 11.8203C1.13542 11.8984 1.22396 11.9479 1.32812 11.9688C1.43229 11.9948 1.53385 11.9948 1.63281 11.9688C1.73177 11.9479 1.82292 11.8984 1.90625 11.8203L6.69531 7.02344L11.4922 11.8203C11.6068 11.9349 11.7474 11.9922 11.9141 11.9922C12.0807 11.9974 12.2214 11.9401 12.3359 11.8203C12.4505 11.7005 12.5078 11.5573 12.5078 11.3906C12.5078 11.224 12.4505 11.0859 12.3359 10.9766L7.53906 6.17969L12.3359 1.38281C12.4505 1.27344 12.5078 1.13542 12.5078 0.96875C12.513 0.796875 12.4557 0.653646 12.3359 0.539062C12.2214 0.424479 12.0807 0.367188 11.9141 0.367188C11.7474 0.367188 11.6068 0.424479 11.4922 0.539062L6.69531 5.33594L1.90625 0.539062C1.82292 0.466146 1.73177 0.416667 1.63281 0.390625C1.53385 0.364583 1.43229 0.364583 1.32812 0.390625C1.22396 0.411458 1.13542 0.460938 1.0625 0.539062C0.984375 0.617188 0.932292 0.708333 0.90625 0.8125C0.880208 0.911458 0.880208 1.01302 0.90625 1.11719C0.932292 1.22135 0.984375 1.3099 1.0625 1.38281L5.85156 6.17969L1.0625 10.9766C0.984375 11.0495 0.929688 11.138 0.898438 11.2422C0.872396 11.3464 0.872396 11.4505 0.898438 11.5547C0.924479 11.6589 0.979167 11.7474 1.0625 11.8203Z'
                fill='#A9A9A8'
              />
            </svg>
          </button>
        ) : (
          <button type='button' onClick={toggleOptions} className={"custom-select__file"}>
            <div>{t("uploadProject")}*</div>
            <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M0.882812 6.16406C0.882812 6.33073 0.942708 6.47396 1.0625 6.59375C1.18229 6.70833 1.32292 6.76562 1.48438 6.76562H6.33594V11.6172C6.33594 11.7786 6.39323 11.9167 6.50781 12.0312C6.6276 12.151 6.77083 12.2109 6.9375 12.2109C7.09896 12.2109 7.23698 12.151 7.35156 12.0312C7.46615 11.9167 7.52344 11.7786 7.52344 11.6172V6.76562H12.3906C12.5521 6.76562 12.6901 6.70833 12.8047 6.59375C12.9245 6.47396 12.9844 6.33073 12.9844 6.16406C12.9844 6.0026 12.9245 5.86458 12.8047 5.75C12.6901 5.63021 12.5521 5.57031 12.3906 5.57031H7.52344V0.710938C7.52344 0.554688 7.46615 0.416667 7.35156 0.296875C7.23698 0.177083 7.09896 0.117188 6.9375 0.117188C6.77083 0.117188 6.6276 0.177083 6.50781 0.296875C6.39323 0.416667 6.33594 0.554688 6.33594 0.710938V5.57031H1.48438C1.32292 5.57031 1.18229 5.63021 1.0625 5.75C0.942708 5.86458 0.882812 6.0026 0.882812 6.16406Z'
                fill='black'
              />
            </svg>
          </button>
        )}
        {isOptionsOpen && (
          <ul className={"custom-select__list"}>
            <li className={"custom-select__import"}>
              <label htmlFor={"import"}>
                <span>{t("uploadFiles")}</span>
                <span>
                  <svg width='16' height='19' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M8.37109 11.1719C8.26693 11.276 8.14453 11.3281 8.00391 11.3281C7.85807 11.3281 7.73047 11.276 7.62109 11.1719C7.51693 11.0677 7.46484 10.9453 7.46484 10.8047V3.07031L7.50391 1.97656L6.94141 2.57031L5.76953 3.79688C5.67578 3.90625 5.55339 3.96094 5.40234 3.96094C5.26172 3.96094 5.14453 3.91667 5.05078 3.82812C4.96224 3.73958 4.91797 3.6276 4.91797 3.49219C4.91797 3.36198 4.97005 3.24479 5.07422 3.14062L7.61328 0.679688C7.68099 0.611979 7.74349 0.565104 7.80078 0.539062C7.86328 0.513021 7.93099 0.5 8.00391 0.5C8.07161 0.5 8.13411 0.513021 8.19141 0.539062C8.25391 0.565104 8.31901 0.611979 8.38672 0.679688L10.9258 3.14062C11.0299 3.24479 11.082 3.36198 11.082 3.49219C11.082 3.6276 11.0352 3.73958 10.9414 3.82812C10.8477 3.91667 10.7305 3.96094 10.5898 3.96094C10.444 3.96094 10.3242 3.90625 10.2305 3.79688L9.05859 2.57031L8.49609 1.98438L8.53516 3.07031V10.8047C8.53516 10.9453 8.48047 11.0677 8.37109 11.1719ZM12.2383 16.5H3.76172C3.00651 16.5 2.4388 16.3125 2.05859 15.9375C1.68359 15.5625 1.49609 15.0052 1.49609 14.2656V7.82031C1.49609 7.07552 1.68359 6.51823 2.05859 6.14844C2.4388 5.77344 3.00651 5.58594 3.76172 5.58594H5.83984V6.66406H3.77734C3.39193 6.66406 3.09505 6.76562 2.88672 6.96875C2.68359 7.17188 2.58203 7.47656 2.58203 7.88281V14.2109C2.58203 14.6068 2.68359 14.9062 2.88672 15.1094C3.09505 15.3125 3.39193 15.4141 3.77734 15.4141H12.2227C12.6081 15.4141 12.9049 15.3125 13.1133 15.1094C13.3216 14.9062 13.4258 14.6068 13.4258 14.2109V7.88281C13.4258 7.47656 13.3216 7.17188 13.1133 6.96875C12.9049 6.76562 12.6081 6.66406 12.2227 6.66406H10.168V5.58594H12.2383C12.9935 5.58594 13.5586 5.77344 13.9336 6.14844C14.3138 6.52344 14.5039 7.08073 14.5039 7.82031V14.2656C14.5039 15.0052 14.3138 15.5625 13.9336 15.9375C13.5586 16.3125 12.9935 16.5 12.2383 16.5Z'
                      fill='black'
                    />
                  </svg>
                </span>
              </label>
              <input
                accept=".zip,.rar,.7zip,'.tar''.rar','.gz'"
                onChange={onChangeFile}
                type={"file"}
                id={"import"}
                style={{ display: "none", visibility: "hidden" }}
              />
            </li>
            <GitLinkModal toggleOptions={toggleOptions} />
            <DropboxModal toggleOptions={toggleOptions} />
            <DropboxModal toggleOptions={toggleOptions} isDropBox={true} />
          </ul>
        )}
      </div>
    </div>
  );
};

export default CustomFileSelect;
