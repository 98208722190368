import makeRequest from "../makeRequest";
import { IMemberAddData, IPermission } from "../../interfaces/members/members";

export function getMembersApi(
  page: string,
  name?: string,
  sortBy?: string,
  sortValue?: string,
  teamID?: string,
  count?: string
) {
  return makeRequest.get(
    `api/members/${page ? page : ""}${name ? name : ""}${sortBy ? sortBy : ""}${sortValue ? sortValue : ""}${teamID ? teamID : ""}${count ? count : ""}`
  );
}

export function createMemberApi(data: IMemberAddData) {
  return makeRequest({
    method: "POST",
    data: data,
    url: "api/members/add"
  });
}

export function deleteMemberApi(id: number) {
  return makeRequest({
    method: "DELETE",
    url: `
    api / members /${id}/delete`,
    headers: {
      "Content-Type": "Application/json"
    }
  });
}

export function changeMemberRoleApi(id: number, teamID: string, role: string) {
  const actualData = {
    teamID: Number(teamID),
    role: role,
    userID: id
  };
  return makeRequest({
    method: "PATCH",
    url: `api/members/${id}/change_role`,
    data: actualData,
    headers: {
      "Content-Type": "Application/json"
    }
  });
}

export function apiUserFind(value: string, by: string) {
  return makeRequest.get(`api/user/find?by=${by}&value=${value}`);
}

export function removeMemberFromTeamApi(id: number, teamId: string) {
  return makeRequest({
    method: "PATCH",
    url: `api/members/${id}/remove_from_team`,
    data: {
      teamID: Number(teamId),
      userID: id
    }
  });
}

export function configurePermission(id: number, teamID: string, permissions: IPermission) {
  const actualData = {
    teamID: Number(teamID),
    permissions: permissions,
    userID: id
  };
  return makeRequest({
    method: "PUT",
    url: `api/members/${id}/configure_permissions`,
    data: actualData,
    headers: {
      "Content-Type": "Application/json"
    }
  });
}
