import React, { useState } from "react";
import first from "../../../assets/help/01.jpg";
import second from "../../../assets/help/02.jpg";
import third from "../../../assets/help/03.jpg";
import fourth from "../../../assets/help/04.jpg";
import uploadArchive from "../../../assets/help/uploadArchive.png";
import github from "../../../assets/help/github-1.png";
import github2 from "../../../assets/help/github.png";
import dropbox from "../../../assets/help/dropbox.png";
import dropbox1 from "../../../assets/help/dropbox-1.png";
import { useTranslation } from "react-i18next";

interface props {}

const UploadProject: React.FC<props> = () => {
  const { t } = useTranslation();
  const startGuide = [
    {
      title: t("uploadingProject.github.title"),
      img: github,
      text: t("uploadingProject.github.first")
    },
    {
      img: github2,
      text: t("uploadingProject.github.second")
    },
    {
      img: first,
      text: t("uploadingProject.first")
    },
    {
      img: second,
      text: t("uploadingProject.second")
    },
    {
      img: third,
      text: t("uploadingProject.third")
    }
  ];
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <section className={"account help"}>
      <div onClick={() => setVisible(!visible)} className={`help__btn ${visible ? "help__btn--hide" : ""}`}>
        <svg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1.45481 10.1816C1.05315 10.1816 0.727539 9.85603 0.727539 9.45437C0.727539 9.05271 1.05315 8.7271 1.45481 8.7271H14.5457C14.9474 8.7271 15.273 9.05271 15.273 9.45437C15.273 9.85603 14.9474 10.1816 14.5457 10.1816H1.45481ZM1.2933 7.27246C0.836287 7.27246 0.618803 6.70991 0.956968 6.40249L7.32733 0.611254C7.70875 0.264507 8.29125 0.264508 8.67267 0.611255L15.043 6.40249C15.3812 6.70991 15.1637 7.27246 14.7067 7.27246H1.2933Z'
            fill='#DDDDDD'
          />
        </svg>
      </div>
      <h2 onClick={() => setVisible(!visible)} className={"account__title "}>
        {t("startWorking")}
      </h2>
      <div className={"account__info help__info"}>
        <div className={"account__name"}>
          <h4 onClick={() => setVisible(!visible)} className={"account__title help__title"}>
            {t("uploadingProject.title")}
          </h4>
          {visible && (
            <>
              <ul className={"help__list"}>
                <li className={"help__item"}>
                  <p className={"help__item--text"}>{t("uploadingProject.fourth")}</p>
                  <ul className={"help__sub-list"}>
                    <li className={"help__sub-list--item"}>{t("uploadingProject.fifth")}</li>
                    <li className={"help__sub-list--item"}>{t("uploadingProject.sixth")}</li>
                  </ul>
                </li>
                <li className={"help__item"}>
                  <h4 className={"help__item--title"}>{t("uploadingProject.archive.title")}</h4>
                  <div className={"help__image"}>
                    <img src={uploadArchive} alt={"guide"} />
                  </div>
                  <p className={"help__item--text"}>{t("uploadingProject.archive.text")}</p>
                  <ul className={"help__sub-list"}>
                    <li className={"help__sub-list--item"}>{t("uploadingProject.archive.first")}</li>
                    <li className={"help__sub-list--item"}>{t("uploadingProject.archive.second")}</li>
                    <li className={"help__sub-list--item"}>{t("uploadingProject.archive.third")}</li>
                  </ul>
                </li>
                <li className={"help__item"}>
                  <h4 className={"help__item--title"}>{t("uploadingProject.dropbox.title")}</h4>
                  <div className={"help__image"}>
                    <img src={dropbox} alt={"guide"} />
                  </div>
                  <div className={"help__image"}>
                    <img src={dropbox1} alt={"guide"} />
                  </div>
                  <p className={"help__item--text"}>{t("uploadingProject.dropbox.text")}</p>
                  <ul className={"help__sub-list"}>
                    <li className={"help__sub-list--item"}>{t("uploadingProject.dropbox.first")}</li>
                    <li className={"help__sub-list--item"}>{t("uploadingProject.dropbox.second")}</li>
                    <li className={"help__sub-list--item"}>{t("uploadingProject.dropbox.third")}</li>
                  </ul>
                </li>
                {startGuide.map(({ img, text, title }) => (
                  <li className={"help__item"} key={text}>
                    {title && <h4 className={"help__item--title"}>{title}</h4>}
                    <div className={"help__image"}>
                      <img src={img} alt={"guide"} />
                    </div>
                    <p className={"help__item--text"}>{text}</p>
                  </li>
                ))}
              </ul>
              <h4 className={"account__title help__title"}>{t("intersectionsIndicator.title")}</h4>
              <ul className={"help__list"}>
                <li className={"help__item"}>
                  <div className={"help__image"}>
                    <img src={fourth} alt={"guide"} />
                  </div>
                  <p className={"help__item--text"}>{t("intersectionsIndicator.first")}</p>
                  <p className={"help__item--text"}>{t("intersectionsIndicator.second")}</p>
                  <p className={"help__item--text"}>{t("intersectionsIndicator.third")}</p>
                  <p className={"help__item--text"}>{t("intersectionsIndicator.fourth")}</p>
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default UploadProject;
