import React from "react";
import { useTranslation } from "react-i18next";

interface props {}

const Socials: React.FC<props> = () => {
  const { t } = useTranslation();

  const socials = [
    {
      title: "Telegram",
      icon: (
        <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.00002 0C1.34315 0 0 1.34315 0 3.00001V15.0001C0 16.6569 1.34315 18.0001 3.00001 18.0001H15.0001C16.6569 18.0001 18.0001 16.6569 18.0001 15.0001V3.00002C18.0001 1.34315 16.6569 0 15.0001 0H3.00002ZM11.9106 13.8638L7.09282 10.5994L12.6363 6.0114C12.8796 5.81338 12.5831 5.71681 12.2602 5.89643L5.41881 9.85487L2.46371 9.00898C1.82553 8.82977 1.82095 8.42759 2.60698 8.13844L14.1223 4.06615C14.6483 3.84717 15.1559 4.18201 14.9551 4.92026L12.9941 13.3957C12.8571 13.9979 12.4603 14.142 11.9106 13.8638Z'
            fill='#ECEDF5'
          />
        </svg>
      ),
      path: "https://t.me/apprefactoring"
    },
    {
      title: "Email",
      icon: (
        <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.00002 0C1.34315 0 0 1.34315 0 3.00001V15.0001C0 16.6569 1.34315 18.0001 3.00001 18.0001H15.0001C16.6569 18.0001 18.0001 16.6569 18.0001 15.0001V3.00002C18.0001 1.34315 16.6569 0 15.0001 0H3.00002ZM9.72803 9.31345C9.48346 9.56418 9.24472 9.68954 9.0118 9.68954C8.77499 9.68954 8.53625 9.56418 8.29557 9.31345L3.20043 4.14967C3.39065 3.95466 3.73227 3.85716 4.22528 3.85716H13.6003C14.1787 3.85716 14.5767 3.96262 14.794 4.17355L9.72803 9.31345ZM6.83399 9.009L2.71712 13.1818C2.62007 13.0107 2.57155 12.7142 2.57155 12.2923V5.70179C2.57155 5.29585 2.61619 5.00732 2.70548 4.83619L6.83399 9.009ZM13.775 14.1429H4.39997C3.84484 14.1429 3.45664 14.0454 3.23537 13.8504L7.42211 9.59403L7.81226 9.994C8.20046 10.3761 8.6003 10.5671 9.0118 10.5671C9.41941 10.5671 9.81925 10.3761 10.2113 9.994L10.5957 9.59403L14.794 13.8564C14.5999 14.0474 14.2603 14.1429 13.775 14.1429ZM15.4288 12.2923C15.4288 12.7062 15.3822 13.0007 15.289 13.1758L11.1838 9.009L15.3006 4.8541C15.3861 5.01727 15.4288 5.29983 15.4288 5.70179V12.2923Z'
            fill='#ECEDF5'
          />
        </svg>
      ),
      path: "mailto:support@apprefactoring.com"
    }
  ];
  return (
    <div className={"contact__socials"}>
      {socials.map(({ title, icon, path }) => (
        <a target={"_blank"} rel={"noreferrer"} href={path} key={title} className={"contact__social"}>
          {icon}
          {title}
        </a>
      ))}
    </div>
  );
};

export default Socials;
