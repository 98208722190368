import React, { useEffect } from "react";
import UploadForm from "./components/upload-form/upload-form";
import Database from "./components/database/database";
import Header from "../../components/header/header";
import { useDispatch, useSelector } from "react-redux";
import { clearProjects, getProjectsThunk, projectsSelector, setAllProjects } from "../../store/slices/projects-slice";
import Loader from "../../shared/loader/loader";
import { userSelector } from "../../store/slices/user-slice";
import { useNavigate, useParams } from "react-router-dom";
import { setDocumentTitle } from "../../utils/setDocumentTitle/setDocumentTitle";
import { filterSelector, resetFilters, resetSort, setCount, setPage } from "../../store/slices/project-filter-slice";
import { useTranslation } from "react-i18next";
import { getProjectsApi } from "../../api/project/project";

interface props {}

const Upload: React.FC<props> = () => {
  const navigate = useNavigate();
  const { projects } = useSelector(projectsSelector);
  const { projectOptions } = useSelector(filterSelector);
  const { user } = useSelector(userSelector);
  const { teamId } = useParams();
  const { t } = useTranslation();
  setDocumentTitle(t("myProjects"));
  const serverError = () => {
    navigate("/500");
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getProjectsThunk(
        1,
        "",
        serverError,
        "&sortBy=updatedAt",
        "&sortValue=desc",
        "",
        "",
        projectOptions.count.toString()
      )
    );
    getProjectsApi("?page=1", "", "", "", "", teamId ? `&teamID=${teamId}` : "", "&count=500")
      .then(response => {
        dispatch(setAllProjects(response.items));
      })
      .catch(e => {});
    document.body.style.overflow = "visible";
    return () => {
      dispatch(setAllProjects([]));
      dispatch(setPage(1));
      dispatch(clearProjects());
      dispatch(resetFilters());
      dispatch(resetSort());
    };
  }, []);
  if (!projects.loaded) return <Loader />;
  return (
    <>
      <UploadForm />
      {user.entity.hasProjects ? <Database /> : <div className={"database__empty-project"}>{t("noProjects")}</div>}
    </>
  );
};

export default Upload;
