import React, { useState } from "react";
import CustomFileSelect from "./custom-file-select/custom-file-select";
import CustomSelect from "./custom-select";
import CustomProjectsSelect from "./custom-projects-select/custom-projects-select";
import { IDataSelects } from "../upload-form";

interface props {
  resetSuccess: () => void;
}

const Selects: React.FC<props> = ({ resetSuccess }) => {
  const [selects, setSelects] = useState({
    fileUpload: false,
    type: false,
    compare: false
  });

  const selectsHandler = (data: IDataSelects) => {
    setSelects(data);
  };
  return (
    <>
      <CustomFileSelect resetSuccess={resetSuccess} visibleHandler={selectsHandler} isVisible={selects.fileUpload} />
      <CustomSelect visibleHandler={selectsHandler} isVisible={selects.type} />
      <CustomProjectsSelect isVisible={selects.compare} visibleHandler={selectsHandler} />
    </>
  );
};

export default Selects;
