import { Action, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { IHash, IHashState } from "../../interfaces/hashes/hashes";
import { IProject } from "../../interfaces/projects/projects";
import { getHashesApi } from "../../api/hashes/hashes";

const initialState: IHashState = {
  hashes: {
    entity: {
      current_page: 0,
      items: [],
      items_at_page: 0,
      pages_count: 0
    },
    hashType: null,
    id: null,
    target_project_id: {} as IProject,
    confirmLoading: false,
    count: 25,
    loaded: false,
    errors: {
      isError: false,
      message: ""
    }
  }
};

export const hashSelector = (state: { hashes: IHashState }) => state.hashes;

const hashSlice = createSlice({
  name: "hashes",
  initialState,
  reducers: {
    getHashes: (state, action: PayloadAction<any>) => {
      state.hashes.loaded = true;
      state.hashes.entity = action.payload ? action.payload.intersection : [];
      state.hashes.target_project_id = action.payload ? action.payload.target_project_id : {};
      state.hashes.errors.isError = false;
    },
    errorHashes: (state, action: PayloadAction<string>) => {
      state.hashes.loaded = true;
      state.hashes.errors.isError = true;
      state.hashes.errors.message = action.payload;
    },
    setConfirmLoading: (state, action: PayloadAction<boolean>) => {
      state.hashes.confirmLoading = action.payload;
    },
    setCurrentId: (state, action: PayloadAction<number>) => {
      state.hashes.id = action.payload;
    },
    setHashType: (state, action: PayloadAction<number>) => {
      state.hashes.hashType = action.payload;
    },
    resetHash: state => {
      state.hashes.id = null;
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.hashes.count = action.payload;
    }
  }
});

export const { errorHashes, getHashes, setConfirmLoading, setCurrentId, setHashType, resetHash, setCount } =
  hashSlice.actions;

export const getHashesThunk = (
  data: string,
  count: number,
  ignore: boolean
): ThunkAction<void, IHashState, unknown, Action<string>> => {
  return async dispatch => {
    try {
      dispatch(setConfirmLoading(true));
      const hashes: IHash[] | undefined = await getHashesApi(
        data,
        `?page=1`,
        `&count=${count}`,
        `&ignored=${ignore}`
      ).then(response => {
        if (response && response.data) {
          return response.data;
        }
      });
      dispatch(getHashes(hashes));
      dispatch(setConfirmLoading(false));
    } catch (e: any) {
      dispatch(errorHashes(e.response.data.detail));
      dispatch(setConfirmLoading(false));
    }
  };
};

export default hashSlice.reducer;
