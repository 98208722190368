import styles from "./styles.module.css";
import SearchIcon from "../../../../../../assets/search-svgrepo-com.svg";
import { ChangeEvent, useState } from "react";
import IntersectionSearchResults from "./components/intersection-search-results/intersection-search-results";

function IntersectionSearchInput() {
  const [value, setValue] = useState("");

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        <img src={SearchIcon} className={styles.icon} alt='' />
        <input value={value} onChange={onChange} className={styles.input} type='text' />
      </div>
      <IntersectionSearchResults query={value} />
    </div>
  );
}

export default IntersectionSearchInput;
