import React from "react";
import "./onBoarding.css";
import OnboardSlider from "./components/onboard-slider";
import { useTranslation } from "react-i18next";

interface props {}

const OnBoarding: React.FC<props> = () => {
  const { t } = useTranslation();
  return (
    <section className={"onboard"}>
      <h2 className={"onboard__title"}>{t("welcome")}</h2>
      <OnboardSlider />
    </section>
  );
};

export default OnBoarding;
