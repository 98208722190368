import "./pagination.css";
import React from "react";

interface PaginationHasNextProps {
  value: number;

  onSet(): void;

  onIncrement(): void;
  isNoArrows?: boolean;
}

export const PaginationHasNext: React.FC<PaginationHasNextProps> = ({ value, onSet, onIncrement, isNoArrows }) => {
  return (
    <>
      <div className={"pagination-button"} onClick={onSet}>
        <span>{value}</span>
      </div>
      {!isNoArrows && (
        <button onClick={onIncrement} className={"pagination-button__icon--next"}>
          <svg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M19.4111 7.66309C19.4111 7.89941 19.318 8.10352 19.1318 8.27539L12.3428 15.0645C12.1709 15.2292 11.974 15.3115 11.752 15.3115C11.5299 15.3115 11.3402 15.2363 11.1826 15.0859C11.0251 14.9427 10.9463 14.7565 10.9463 14.5273C10.9463 14.4199 10.9678 14.3161 11.0107 14.2158C11.0465 14.1084 11.1003 14.0189 11.1719 13.9473L13.75 11.3262L17.5527 7.8457L17.7568 8.29688L14.5879 8.47949H1.35352C1.12435 8.47949 0.93457 8.4043 0.78418 8.25391C0.626628 8.10352 0.547852 7.90658 0.547852 7.66309C0.547852 7.42676 0.626628 7.2334 0.78418 7.08301C0.93457 6.93262 1.12435 6.85742 1.35352 6.85742H14.5879L17.7568 7.0293L17.5527 7.50195L13.75 4.01074L11.1719 1.38965C11.1003 1.31803 11.0465 1.2321 11.0107 1.13184C10.9678 1.02441 10.9463 0.916992 10.9463 0.80957C10.9463 0.580404 11.0251 0.394206 11.1826 0.250977C11.3402 0.100586 11.5299 0.0253906 11.752 0.0253906C11.8665 0.0253906 11.974 0.046875 12.0742 0.0898438C12.1745 0.132812 12.2783 0.204427 12.3857 0.304688L19.1318 7.06152C19.318 7.2334 19.4111 7.43392 19.4111 7.66309Z'
              fill='#535353'
            />
          </svg>
        </button>
      )}
    </>
  );
};
