import React from "react";

interface props {}

const LogData: React.FC<props> = () => {
  return (
    <section className={"account privacy"}>
      <h2 className={"account__title"}>Log Data</h2>
      <div className={"account__info"}>
        <p className={"privacy__text"}>
          I want to inform you that whenever you use my Service, in a case of an error in the app I collect data and
          information (through third-party products) on your phone called Log Data. This Log Data may include
          information such as your device Internet Protocol (“IP”) address, device name, operating system version, the
          configuration of the app when utilizing my Service, the time and date of your use of the Service, and other
          statistics.
        </p>
      </div>
    </section>
  );
};

export default LogData;
