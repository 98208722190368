import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjects, projectsSelector } from "../../../../../../../../../store/slices/projects-slice";
import { getProjectsApi } from "../../../../../../../../../api/project/project";
import Loader from "../../../../../../../../../shared/loader/loader";
import {
  filterSelector,
  resetFilters,
  resetSort,
  setFilter,
  setPage,
  typesReset
} from "../../../../../../../../../store/slices/project-filter-slice";
import { useNavigate, useParams } from "react-router-dom";

interface props {
  value: string;
}

const Languages: React.FC<props> = ({ value }) => {
  const [sortStatus, setSortStatus] = useState<string>("");
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const { teamId } = useParams();
  const navigate = useNavigate();
  const { projectOptions } = useSelector(filterSelector);
  const dispatch = useDispatch();
  const sortStatusHandler = async (status: string) => {
    if (status === sortStatus) {
      await dispatch(typesReset());
      await dispatch(resetSort());
      await dispatch(resetFilters());
      await setSortStatus("");
      dispatch(setFilter({ filterBy: "", filterValue: "" }));
      request("", "");
      return;
    }
    await dispatch(typesReset());
    await dispatch(resetSort());
    await dispatch(resetFilters());
    await dispatch(setPage(1));
    setSortStatus(status);
    dispatch(setFilter({ filterValue: status, filterBy: value }));
    request(value, status);
  };

  function request(by: string, value: string) {
    setConfirmLoading(true);
    getProjectsApi(
      `?page=1`,
      "",
      ``,
      by ? `&filterBy=${by}` : "",
      value ? `&filterValue=${value}` : "",
      teamId ? `&teamID=${teamId}` : ""
    )
      .then(response => {
        dispatch(getProjects(response));
      })
      .catch(e => {
        if (e.response.status === 500 || e.response.status === 0) {
          navigate("/500");
        }
      })
      .finally(() => setConfirmLoading(false));
  }

  const techs = [
    {
      text: "All",
      value: "all"
    },
    {
      text: "Java",
      value: "java"
    },
    {
      text: "Swift",
      value: "swift"
    },
    {
      text: "JS",
      value: "js"
    },
    {
      text: "TS",
      value: "ts"
    },
    {
      text: "C#",
      value: "csharp"
    },
    {
      text: "Kotlin",
      value: "kotlin"
    },
    {
      text: "Objective-c",
      value: "objective-c"
    },
    {
      text: "GO",
      value: "go"
    },
    {
      text: "PHP",
      value: "php"
    }
  ];

  return (
    <>
      {confirmLoading && <Loader />}
      {techs.map(({ text, value }) => (
        <button
          key={value}
          onClick={() => sortStatusHandler(value)}
          className={`project-sorting__btn ${projectOptions.filter.filterValue === value ? "project-sorting__btn--active" : ""}`}
        >
          {text}
        </button>
      ))}
    </>
  );
};

export default Languages;
