import React from "react";
import { Link, useParams } from "react-router-dom";
import ProjectSearchInput from "../../../upload/components/database/components/components/project-search-input";
import Project from "../../../upload/components/database/components/project";

interface props {}

const Projects: React.FC<props> = () => {
  const { teamId } = useParams();
  return (
    <section className={"database"}>
      <Project />
    </section>
  );
};

export default Projects;
