import React, { useEffect } from "react";
import UploadForm from "../upload/components/upload-form/upload-form";
import MembersWrapper from "./components/members/components/members-wrapper";
import Header from "../../components/header/header";
import { useParams } from "react-router-dom";
import { getTeamThunk, teamSelector } from "../../store/slices/team-slice";
import { useDispatch, useSelector } from "react-redux";
import { setDocumentTitle } from "../../utils/setDocumentTitle/setDocumentTitle";
import { useTranslation } from "react-i18next";
import Loader from "../../shared/loader/loader";
import { getProjectsApi } from "../../api/project/project";
import { setAllProjects } from "../../store/slices/projects-slice";

interface props {}

const Members: React.FC<props> = () => {
  const { teamId } = useParams();
  const { team } = useSelector(teamSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (teamId) {
      dispatch(getTeamThunk(teamId));
      getProjectsApi("?page=1", "", "", "", "", teamId ? `&teamID=${teamId}` : "", "&count=500")
        .then(response => {
          dispatch(setAllProjects(response.items));
        })
        .catch(e => {});
      return () => {
        dispatch(setAllProjects([]));
      };
    }
  }, [teamId]);
  if (!team.loaded) return <Loader />;

  setDocumentTitle(`${team.entity.name ? `${team.entity.name}'s` : ""} Members`);
  return (
    <>
      <UploadForm isFromTeam={true} />
      <MembersWrapper />
    </>
  );
};

export default Members;
