import React from "react";
import Header from "../../components/header/header";
import TicketInfo from "./components/ticket-info/ticket-info";
import TicketMessages from "./components/ticket-messages/ticket-messages";
import TicketMessageForm from "./components/ticket-message-form/ticket-message-form";
import { useTranslation } from "react-i18next";

interface props {}

const TicketPage: React.FC<props> = () => {
  const { t } = useTranslation();
  return (
    <>
      <TicketInfo />
      <TicketMessages />
      <TicketMessageForm />
    </>
  );
};

export default TicketPage;
