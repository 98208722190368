import React, { useEffect } from "react";
import "./layout.css";
import Aside from "../components/aside/aside";
import { getToken } from "../utils/authLocalStorage";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { sidebarSelector } from "../store/slices/sidebar-slice";
import Header from "../components/header/header";
import { useTranslation } from "react-i18next";

interface props {}

const Layout: React.FC<props> = ({}) => {
  const token = getToken();
  const navigate = useNavigate();
  const { sidebar } = useSelector(sidebarSelector);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);
  return (
    <div className={"layout"}>
      <div className={"layoutWrapper"}>
        <Aside />
        <div className={"content"}>
          <main className={`main ${sidebar.isVisible ? "main-opened" : ""}`}>
            <Header />
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
