import React, { useState } from "react";
import "../notifications.css";
import { deleteNotification, makeAsReadNotification } from "../../../../../api/notifications/notifications";
import { getUserInfo } from "../../../../../api/user/user";
import { auth, logout } from "../../../../../store/slices/user-slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { INotification } from "../../../../../interfaces/user/user";
import moment from "moment";
import Loader from "../../../../../shared/loader/loader";
import { useTranslation } from "react-i18next";

interface props {
  notification: INotification;
}

const Notification: React.FC<props> = ({ notification }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { id, isViewed, addedAt, relatedEntityId, text, type } = notification;
  const navigate = useNavigate();
  const removeNotification = () => {
    deleteNotification(id).then(response => {
      if (response && response.status === 204) {
        getUserInfo()
          .then(response => {
            if (response && response.data) {
              dispatch(auth(response.data.user));
            }
          })
          .catch(e => {
            if (e.response.status === 403) {
              navigate("/403");
            }
            if (e.response.status === 404) {
              navigate("/404");
            }
            if (e.response.status === 500) {
              navigate("/500");
            }
          });
      }
    });
  };
  const readNotification = () => {
    if (!isViewed) {
      makeAsReadNotification(id)
        .then(response => {
          if (response && response.status === 200) {
            document.body.style.overflow = "visible";
            redirect();
          }
        })
        .catch(e => {
          if (e.response.status === 403) {
            navigate("/403");
          }
          if (e.response.status === 404) {
            navigate("/404");
          }
          if (e.response.status === 500) {
            navigate("/500");
          }
        });
      return;
    } else {
      document.body.style.overflow = "visible";
      redirect();
    }
  };
  const redirect = () => {
    if (type === "team" && relatedEntityId) {
      navigate(`/teams/${relatedEntityId}/members`);
    }
    if (type === "project" && relatedEntityId) {
      navigate(`/projects/${relatedEntityId}`);
    }
    if (type === "ticket" && relatedEntityId) {
      navigate(`/support/${relatedEntityId}`);
    }
    getUserInfo()
      .then(response => {
        if (response && response.data) {
          dispatch(auth(response.data.user));
        }
      })
      .catch(e => {
        if (e.response.status === 403) {
          logout();
          navigate("/403");
        }
        if (e.response.status === 404) {
          logout();
          navigate("/404");
        }
        if (e.response.status === 500) {
          navigate("/500");
        }
      });
  };
  const { t } = useTranslation();
  return (
    <div className={"notification"}>
      {confirmLoading && <Loader />}
      <div className={"notification__top"}>
        <p
          onClick={readNotification}
          className={`notification__top--status ${isViewed ? "notification__top--status--read" : ""}`}
        >
          {isViewed ? t("read") : t("new")}
        </p>
        <div className={"notification__top--date"}>
          {moment(addedAt).format("DD.MM.YYYY")}
          <svg
            onClick={removeNotification}
            width='8'
            height='8'
            viewBox='0 0 13 13'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1.0625 11.8203C1.13542 11.8984 1.22396 11.9479 1.32812 11.9688C1.43229 11.9948 1.53385 11.9948 1.63281 11.9688C1.73177 11.9479 1.82292 11.8984 1.90625 11.8203L6.69531 7.02344L11.4922 11.8203C11.6068 11.9349 11.7474 11.9922 11.9141 11.9922C12.0807 11.9974 12.2214 11.9401 12.3359 11.8203C12.4505 11.7005 12.5078 11.5573 12.5078 11.3906C12.5078 11.224 12.4505 11.0859 12.3359 10.9766L7.53906 6.17969L12.3359 1.38281C12.4505 1.27344 12.5078 1.13542 12.5078 0.96875C12.513 0.796875 12.4557 0.653646 12.3359 0.539062C12.2214 0.424479 12.0807 0.367188 11.9141 0.367188C11.7474 0.367188 11.6068 0.424479 11.4922 0.539062L6.69531 5.33594L1.90625 0.539062C1.82292 0.466146 1.73177 0.416667 1.63281 0.390625C1.53385 0.364583 1.43229 0.364583 1.32812 0.390625C1.22396 0.411458 1.13542 0.460938 1.0625 0.539062C0.984375 0.617188 0.932292 0.708333 0.90625 0.8125C0.880208 0.911458 0.880208 1.01302 0.90625 1.11719C0.932292 1.22135 0.984375 1.3099 1.0625 1.38281L5.85156 6.17969L1.0625 10.9766C0.984375 11.0495 0.929688 11.138 0.898438 11.2422C0.872396 11.3464 0.872396 11.4505 0.898438 11.5547C0.924479 11.6589 0.979167 11.7474 1.0625 11.8203Z'
              fill='#A9A9A8'
            />
          </svg>
        </div>
      </div>
      <div onClick={readNotification} className={"notification__content"}>
        {text}
      </div>
    </div>
  );
};

export default Notification;
