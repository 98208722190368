import React from "react";
import "../project-sorting.css";
import Name from "./components/name";
import Languages from "./components/languages";
import Status from "./components/status";
import { useSelector } from "react-redux";
import { filterSelector } from "../../../../../../../../store/slices/project-filter-slice";

interface props {
  value: string;
}

const ProjectSortingOptions: React.FC<props> = ({ value }) => {
  const { projectOptions } = useSelector(filterSelector);
  return (
    <div className={`project-sorting__options ${value.length ? "project-sorting__options--show" : ""}`}>
      {projectOptions.currentOption === "name" && <Name value={value} />}
      {projectOptions.currentOption === "updatedAt" && <Name value={value} />}
      {projectOptions.currentOption === "type" && <Languages value={value} />}
      {projectOptions.currentOption === "state" && <Status value={value} />}
      {projectOptions.currentOption === "crossPercentage" && <Name value={value} />}
    </div>
  );
};

export default ProjectSortingOptions;
