import makeRequest from "../makeRequest";

export function createMessage(id: string, data: any) {
  const newData = new FormData();
  newData.append("text", data.text);
  newData.append("type", "user");
  if (data.fileName) {
    newData.append("fileName", data.fileName);
  }
  return makeRequest({
    method: "POST",
    url: `api/tickets/${id}/messages`,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: newData
  });
}
export function getMessagesApi(id: string) {
  return makeRequest.get(`api/tickets/${id}/messages`);
}
