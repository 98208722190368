import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { usernamePatten } from "../../../../../constants/patterns";
import { setArchiveUrl } from "../../../../../store/slices/upload-project-slice";

interface props {
  isDropBox?: boolean;
  toggleOptions: () => void;
}

const DropboxModal: React.FC<props> = ({ isDropBox, toggleOptions }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors }
  } = useForm();
  const [error, setError] = useState<string>("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showHandler = () => {
    document.body.style.overflow = "hidden";
    setVisible(true);
  };
  const hideHandler = () => {
    reset();
    setVisible(false);
    document.body.style.overflow = "visible";
  };
  const onFinish = (e: any) => {
    if (isDropBox && !e.archiveUrl.includes("dropbox.com")) {
      setError(t("dropboxError"));
      return;
    }
    if (!isDropBox && !e.archiveUrl.includes("https://drive.google.com/file/d/")) {
      setError(t("googleDiskError"));
      return;
    }
    dispatch(setArchiveUrl(e.archiveUrl));
    toggleOptions();
    hideHandler();
  };
  return (
    <>
      <li onClick={showHandler} className={"custom-select__import"}>
        <div>
          {isDropBox ? "Dropbox" : "Google Disk"}
          {isDropBox ? (
            <svg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M4 0.833496L8 3.3335L4 5.8335L0 3.3335L4 0.833496ZM12 0.833496L16 3.3335L12 5.8335L8 3.3335L12 0.833496ZM0 8.3335L4 5.8335L8 8.3335L4 10.8335L0 8.3335ZM12 5.8335L16 8.3335L12 10.8335L8 8.3335L12 5.8335ZM4 11.6668L8 9.16683L12 11.6668L8 14.1668L4 11.6668Z'
                fill='black'
              />
            </svg>
          ) : (
            <svg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M10.5938 9.34062L5.48125 0.5H10.5188L15.6313 9.34062H10.5938ZM6.29688 10.0781L3.77813 14.5H13.4812L16 10.0781H6.29688ZM4.81563 1.60625L0 10.0781L2.51875 14.5L7.40625 6.025L4.81563 1.60625Z'
                fill='black'
              />
            </svg>
          )}
        </div>
      </li>
      {visible && (
        <div className={"logout-modal-wrapper"}>
          <div onClick={hideHandler} className={"create-team__backdrop"}></div>
          <form onSubmit={handleSubmit(onFinish)} className={"logout-modal change-password"}>
            <svg
              onClick={hideHandler}
              className={"create-team__icon"}
              width='11'
              height='11'
              viewBox='0 0 11 11'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0.804688 10.2803C0.86849 10.3486 0.945964 10.3919 1.03711 10.4102C1.12826 10.4329 1.21712 10.4329 1.30371 10.4102C1.3903 10.3919 1.47005 10.3486 1.54297 10.2803L5.7334 6.08301L9.93066 10.2803C10.0309 10.3805 10.154 10.4307 10.2998 10.4307C10.4456 10.4352 10.5687 10.3851 10.6689 10.2803C10.7692 10.1755 10.8193 10.0501 10.8193 9.9043C10.8193 9.75846 10.7692 9.6377 10.6689 9.54199L6.47168 5.34473L10.6689 1.14746C10.7692 1.05176 10.8193 0.93099 10.8193 0.785156C10.8239 0.634766 10.7738 0.50944 10.6689 0.40918C10.5687 0.308919 10.4456 0.258789 10.2998 0.258789C10.154 0.258789 10.0309 0.308919 9.93066 0.40918L5.7334 4.60645L1.54297 0.40918C1.47005 0.345378 1.3903 0.302083 1.30371 0.279297C1.21712 0.25651 1.12826 0.25651 1.03711 0.279297C0.945964 0.297526 0.86849 0.34082 0.804688 0.40918C0.736328 0.477539 0.690755 0.557292 0.667969 0.648438C0.645182 0.735026 0.645182 0.823893 0.667969 0.915039C0.690755 1.00618 0.736328 1.08366 0.804688 1.14746L4.99512 5.34473L0.804688 9.54199C0.736328 9.60579 0.688477 9.68327 0.661133 9.77441C0.638346 9.86556 0.638346 9.95671 0.661133 10.0479C0.683919 10.139 0.731771 10.2165 0.804688 10.2803Z'
                fill='#C6C7C7'
              />
            </svg>
            <div className={"create-team__icon-wrapper"}>
              <h2 className={"create-team__title"}>{isDropBox ? "Dropbox" : "Google Disk"}</h2>
            </div>
            <label className={"password__label"}>{isDropBox ? `${t("link")}` : `${t("link")}`}</label>
            <div className={"password__input"}>
              <input
                type={"text"}
                {...register("archiveUrl", {
                  required: {
                    value: true,
                    message: t("required")
                  },
                  onBlur: e => setValue("archiveUrl", e.target.value.trim())
                })}
                placeholder={isDropBox ? t("dropBoxPlaceholder") : t("googleDiskPlaceholder")}
              />
            </div>
            {error.length > 0 && <p className={"upload-form__error"}>{error}</p>}
            {errors.archiveUrl && errors.archiveUrl.type.length > 0 && (
              <p className={"upload-form__error"}>{errors.archiveUrl.message}</p>
            )}
            <div className={"logout-modal__btn-wrapper"}>
              <button className={"logout-modal__btn"}>{t("save")}</button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default DropboxModal;
