import { Action, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { IProject, IProjectState } from "../../interfaces/projects/projects";
import { deleteProjectApi, getProjectApi, getProjectsApi } from "../../api/project/project";
import { AxiosResponse } from "axios";

const initialState: IProjectState = {
  projects: {
    entity: {
      current_page: 0,
      items: [],
      items_at_page: 0,
      pages_count: 0
    },
    allProjects: [],
    loaded: false,
    errors: {
      isError: false,
      message: ""
    }
  },
  project: {
    entity: {} as IProject,
    loaded: false,
    errors: {
      isError: false,
      message: ""
    }
  }
};

export const projectsSelector = (state: { projects: IProjectState }) => state.projects;

const projectsSlice = createSlice({
  name: "projects",
  initialState: initialState,
  reducers: {
    getProjects: (state, action: PayloadAction<any>) => {
      state.projects.entity = action.payload ? action.payload : [];
      state.projects.loaded = true;
      state.projects.errors.isError = false;
    },
    projectsError: (state, action: PayloadAction<string>) => {
      state.projects.loaded = true;
      state.projects.errors.isError = true;
      state.projects.errors.message = action.payload;
    },
    setAllProjects: (state, action: PayloadAction<any>) => {
      state.projects.allProjects = action.payload;
    },
    clearProjects: state => {
      state.projects.entity = {
        current_page: 0,
        items: [],
        items_at_page: 0,
        pages_count: 0
      };
      state.projects.loaded = false;
      state.projects.errors.isError = false;
    },
    clearProject: state => {
      state.project.entity = {} as IProject;
      state.project.loaded = false;
      state.project.errors.isError = false;
    },
    getProject: (state, action: PayloadAction<IProject | undefined>) => {
      state.project.entity = action.payload ? action.payload : ({} as IProject);
      state.project.loaded = true;
      state.project.errors.isError = false;
    },
    projectError: (state, action: PayloadAction<string>) => {
      state.project.loaded = true;
      state.project.errors.isError = true;
      state.project.errors.message = action.payload;
    },
    createProject: (state, action: PayloadAction<IProject>) => {
      state.projects.loaded = true;
      state.projects.entity.items = [...state.projects.entity.items, action.payload];
      state.projects.errors.isError = false;
    },
    updateProject: (state, action: PayloadAction<IProject>) => {
      const updatedProjects = state.projects.entity.items.map(project => {
        if (project.id === action.payload.id) {
          return action.payload;
        }
        return project;
      });
      state.projects.loaded = true;
      state.projects.entity.items = updatedProjects;
      state.projects.errors.isError = false;
    },
    deleteProject: (state, action: PayloadAction<number>) => {
      state.projects.loaded = true;
      state.projects.entity.items = state.projects.entity.items.filter(project => {
        if (project.id === action.payload) {
          return false;
        }
        return project;
      });
    },
    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.projects.loaded = action.payload;
    },
    setProjectLoaded: (state, action: PayloadAction<boolean>) => {
      state.project.loaded = action.payload;
    }
  }
});

export const {
  createProject,
  deleteProject,
  getProject,
  getProjects,
  projectError,
  projectsError,
  updateProject,
  clearProject,
  clearProjects,
  setLoaded,
  setProjectLoaded,
  setAllProjects
} = projectsSlice.actions;

// Async Thunk
export const getProjectsThunk = (
  page?: number,
  teamID?: string,
  serverError?: any,
  sortBy?: string,
  sortValue?: string,
  filterBy?: string,
  filterValue?: string,
  count?: string
): ThunkAction<void, IProjectState, unknown, Action<string>> => {
  return async dispatch => {
    try {
      const projects: IProject[] | undefined = await getProjectsApi(
        `?page=${page}`,
        sortBy ? sortBy : ``,
        sortValue ? sortValue : ``,
        filterBy ? filterBy : ``,
        filterValue ? filterValue : ``,
        teamID ? `&teamID=${teamID}` : "",
        count ? `&count=${count}` : ""
      );
      dispatch(getProjects(projects));
    } catch (e: any) {
      if (e.response && e.response.data) {
        dispatch(projectsError(e.response.data.message));
      }
      if (serverError) {
        if (e.response && e.response.status === 500) {
          serverError();
        }
      }
    }
  };
};
export const getProjectThunk = (
  id: string,
  redirect?: any
): ThunkAction<void, IProjectState, unknown, Action<string>> => {
  return async dispatch => {
    try {
      const project: IProject | undefined = await getProjectApi(id);
      dispatch(getProject(project));
    } catch (e: any) {
      if (e.response.status === 403) {
        redirect("/403");
      }
      if (e.response.status === 404) {
        redirect("/404");
      }
      if (e.reponse && e.respone.data) {
        dispatch(projectError(e.response.data.detail));
      }
    }
  };
};

export const deleteProjectThunk = (
  id: number,
  redirect?: any,
  isProjectPage?: boolean
): ThunkAction<void, IProjectState, unknown, Action<string>> => {
  return async dispatch => {
    try {
      dispatch(setProjectLoaded(false));
      deleteProjectApi(id)
        .then(response => {
          if (response && response.status === 200) {
            dispatch(setProjectLoaded(true));
            dispatch(deleteProject(id));
            if (isProjectPage) {
              redirect();
            }
          }
        })
        .catch(e => {
          dispatch(setProjectLoaded(true));
          dispatch(projectError(e.response.data.message));
        });
    } catch (e: any) {}
  };
};

export default projectsSlice.reducer;
