import { Action, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { IMemberAddData, IMembers, IMembersState } from "../../interfaces/members/members";
import { IProject, IProjectState } from "../../interfaces/projects/projects";
import { getProjectsApi } from "../../api/project/project";
import { getProjects, projectsError } from "./projects-slice";
import { changeMemberRoleApi, createMemberApi, deleteMemberApi, getMembersApi } from "../../api/members/members";
import { IChangeRoleData } from "../../shared/custom-select/custom-select";
import { userRoles } from "../../constants/user-roles";

const initialState: IMembersState = {
  members: {
    entity: {
      current_page: 1,
      items: [],
      items_at_page: 10,
      pages_count: 0,
      total_count: 0
    },
    page: 1,
    confirmLoading: false,
    errors: {
      isError: false,
      message: ""
    },
    loaded: false
  }
};

export const membersSelector = (state: { members: IMembersState }) => state.members;

const membersSlice = createSlice({
  name: "Member",
  initialState,
  reducers: {
    getMembers: (state, action: PayloadAction<any>) => {
      state.members.loaded = true;
      state.members.entity = action.payload;
      state.members.errors.isError = false;
    },
    membersError: (state, action: PayloadAction<string>) => {
      state.members.loaded = true;
      state.members.errors.isError = true;
      state.members.errors.message = action.payload;
    },
    createMember: (state, action: PayloadAction<IMembers>) => {
      state.members.loaded = true;
      state.members.entity.items = [...state.members.entity.items, action.payload];
      state.members.errors.isError = false;
    },
    changeMemberRole: (state, action: PayloadAction<IMembers>) => {
      const updatedMembers = state.members.entity.items.map(member => {
        if (member.id === action.payload.id) {
          return action.payload;
        }
        return member;
      });
      state.members.entity.items = updatedMembers;
      state.members.loaded = true;
      state.members.errors.isError = false;
    },
    deleteMember: (state, action: PayloadAction<number>) => {
      const updatedMembers = state.members.entity.items.filter(member => {
        if (member.id === action.payload) {
          return false;
        }
        return member;
      });
      state.members.entity.items = updatedMembers;
      state.members.loaded = true;
      state.members.errors.isError = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.members.confirmLoading = action.payload;
    },
    resetMembers: state => {
      state.members.entity.items = [];
      state.members.loaded = false;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.members.page = action.payload;
    },
    changePerPage: (state, action: PayloadAction<number>) => {
      state.members.entity.items_at_page = action.payload;
    }
  }
});

export const {
  changeMemberRole,
  createMember,
  deleteMember,
  getMembers,
  membersError,
  setLoading,
  resetMembers,
  setPage,
  changePerPage
} = membersSlice.actions;

export const getMembersThunk = (
  page?: number,
  teamID?: string,
  count?: number
): ThunkAction<void, IMembersState, unknown, Action<string>> => {
  return async dispatch => {
    dispatch(setLoading(true));
    try {
      const members: IMembers[] = await getMembersApi(
        page ? `?page=${page}` : `?page=1`,
        ``,
        ``,
        ``,
        teamID ? `&teamID=${teamID}` : "",
        count ? `&count=${count}` : `&count=10`
      ).then(response => {
        if (response && response.data) {
          dispatch(setLoading(false));
          return response.data.members;
        }
      });
      dispatch(getMembers(members));
    } catch (e: any) {
      dispatch(setLoading(false));
      if (e.response && e.response.status === 403) {
        dispatch(membersError(e.response.data.message));
      }
      if (e.response.status === 500 || e.response.status === 0) {
        dispatch(membersError("Something went wrong"));
      } else {
        dispatch(membersError(e.response.data.message || e.response.data.detail));
      }
    }
  };
};

export const addMembersThunk = (
  data: IMemberAddData,
  reset: any
): ThunkAction<void, IMembersState, unknown, Action<string>> => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const member: IMembers = await createMemberApi(data).then(response => {
        if (response && response.data) {
          dispatch(setLoading(false));
          reset();
          dispatch(getMembersThunk());
          return response.data.members.items;
        }
      });
      dispatch(createMember(member));
    } catch (e: any) {
      reset();
      dispatch(setLoading(false));
      dispatch(getMembersThunk());
      dispatch(membersError(e.response.data.message));
    }
  };
};
export const deleteMemberThunk = (id: number): ThunkAction<void, IMembersState, unknown, Action<string>> => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      await deleteMemberApi(id).then(response => {
        if (response.status === 200) {
          dispatch(setLoading(false));
          dispatch(deleteMember(id));
        }
      });
    } catch (e: any) {
      dispatch(setLoading(false));
      dispatch(e.response.data.message);
    }
  };
};

export default membersSlice.reducer;
