import React, { useState } from "react";
import "./notifications.css";
import { useDispatch, useSelector } from "react-redux";
import { auth, setLoaded, userSelector } from "../../../../store/slices/user-slice";
import { useForm } from "react-hook-form";
import { getUserInfo, userNotification } from "../../../../api/user/user";
import SetSlack from "./components/set-slack";
import SetTelegram from "./components/set-telegram";
import Loader from "../../../../shared/loader/loader";
import { setToken } from "../../../../utils/authLocalStorage";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface props {}

interface INotifications {
  email: boolean;
  slack: boolean;
  telegram: boolean;
}

const Notifications: React.FC<props> = () => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const { user } = useSelector(userSelector);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<INotifications>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  if (!user.loaded) {
    return <Loader />;
  }
  const resetUser = () => {
    getUserInfo()
      .then(response => {
        if (response && response.data) {
          dispatch(auth(response.data.user));
        }
      })
      .catch(e => {
        if (e.response.status === 403) {
          navigate("/403");
        }
        if (e.response.status === 500) {
          navigate("/500");
        }
      });
  };

  const onSubmit = (e: INotifications) => {
    const data = {
      slack: user.entity.slack,
      email: user.entity.email,
      telegram: user.entity.telegram,
      enabledNotifications: [...user.entity.enabledNotifications]
    };

    if (e.email && !data.enabledNotifications.includes("email")) {
      data.enabledNotifications.push("email");
    }
    if (!e.email) {
      let newArr = data.enabledNotifications.filter(item => {
        if (item === "email") {
          return false;
        }
        return item;
      });
      data.enabledNotifications = newArr;
    }
    if (e.slack && user.entity.slack && !data.enabledNotifications.includes("slack")) {
      data.enabledNotifications.push("slack");
    }
    if (!e.slack) {
      let newArr = data.enabledNotifications.filter(item => {
        if (item === "slack") {
          return false;
        }
        return item;
      });
      data.enabledNotifications = newArr;
    }
    if (e.telegram && user.entity.telegram && !data.enabledNotifications.includes("telegram")) {
      data.enabledNotifications.push("telegram");
    }
    if (!e.telegram) {
      let newArr = data.enabledNotifications.filter(item => {
        if (item === "telegram") {
          return false;
        }
        return item;
      });
      data.enabledNotifications = newArr;
    }
    dispatch(setLoaded(false));
    setConfirmLoading(true);
    userNotification(data)
      .then(response => {
        if (response && response.data) {
          if (response.data.member) {
            resetUser();
          }
          if (response.data.token) {
            setToken(response.data.token);
          }
        }
      })
      .finally(() => {
        setConfirmLoading(false);
        dispatch(setLoaded(true));
      });
  };

  const defaultValues = {
    email:
      user.entity.enabledNotifications && user.entity.email
        ? user.entity.enabledNotifications.includes("email")
        : false,
    slack:
      user.entity.enabledNotifications && user.entity.slack
        ? user.entity.enabledNotifications.includes("slack")
        : false,
    telegram:
      user.entity.enabledNotifications && user.entity.telegram
        ? user.entity.enabledNotifications.includes("telegram")
        : false
  };

  return (
    <section className={"account"}>
      {confirmLoading && <Loader />}
      <h2 className={"account__title"}>{t("notifications")}</h2>
      <form onSubmit={handleSubmit(onSubmit)} className={"account__info account__info--form"}>
        <div className={"account__info--form-item"}>
          <div className={"account__name"}>
            <h4 className={"account__title"}>{t("email")}</h4>
            <div className={`account__subtitle ${user.entity.email === null ? "account__subtitle--hidden" : ""}`}>
              <label className={"notification__label"}>
                {t("enable")}
                <input
                  disabled={!user.entity.email}
                  {...register("email", { value: defaultValues.email })}
                  type={"checkbox"}
                  className={"notification__checkbox"}
                />
                <span className={"notification__checkmark"} />
              </label>
            </div>
          </div>
          <div className={"account__name"}>
            <h4 className={"account__title"}>Slack</h4>
            <div className={"account__subtitle"}>
              <p className={"account__text"}>{user.entity.slack?.length ? user.entity.slack : t("enterSlack")}</p>
              <SetSlack />
            </div>
            <div className={`account__subtitle ${user.entity.slack === null ? "account__subtitle--hidden" : ""}`}>
              <label className={"notification__label"}>
                {t("enable")}
                <input
                  disabled={!user.entity.slack}
                  type={"checkbox"}
                  {...register("slack", { value: defaultValues.slack })}
                  className={"notification__checkbox"}
                />
                <span className={"notification__checkmark"} />
              </label>
            </div>
          </div>
          <div className={"telegram__channel"}>
            <p className={"telegram__text"}>{t("getWebhook")}</p>
            <a
              href={"https://get.slack.help/hc/en-us/articles/115005265063-incoming-webhooks-for-slack"}
              target={"_blank"}
              rel={"noreferrer"}
              className={"telegram__link"}
            >
              {t("link")}
            </a>
          </div>
          <div className={"account__name"}>
            <h4 className={"account__title"}>Telegram</h4>
            <div className={"account__subtitle"}>
              <p className={"account__text"}>{user.entity.telegram?.length ? user.entity.telegram : t("enterTgId")}</p>
              <SetTelegram />
            </div>
            <div className={`account__subtitle ${user.entity.telegram === null ? "account__subtitle--hidden" : ""}`}>
              <label className={"notification__label"}>
                {t("enable")}
                <input
                  disabled={!user.entity.telegram}
                  {...register("telegram", { value: defaultValues.telegram })}
                  type={"checkbox"}
                  className={"notification__checkbox"}
                />
                <span className={"notification__checkmark"} />
              </label>
            </div>
            <div className={"telegram__channel"}>
              <p className={"telegram__text"}>{t("subscribeTg")}</p>
              <a
                href={"https://t.me/apprefactoring_bot"}
                target={"_blank"}
                rel={"noreferrer"}
                className={"telegram__link"}
              >
                {t("openBot")}
                <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M7.81641 12.1094C8.02344 12.1094 8.20117 12.0293 8.34961 11.8691C8.49805 11.7129 8.62305 11.5 8.72461 11.2305L12.375 1.70898C12.4219 1.58398 12.459 1.46484 12.4863 1.35156C12.5137 1.23828 12.5273 1.13672 12.5273 1.04688C12.5273 0.855469 12.4707 0.705078 12.3574 0.595703C12.248 0.486328 12.0996 0.431641 11.9121 0.431641C11.8184 0.431641 11.7168 0.445312 11.6074 0.472656C11.498 0.5 11.3789 0.537109 11.25 0.583984L1.68164 4.25195C1.44336 4.3418 1.24414 4.46094 1.08398 4.60938C0.927734 4.75391 0.849609 4.93164 0.849609 5.14258C0.849609 5.40039 0.9375 5.58984 1.11328 5.71094C1.28906 5.82812 1.51172 5.92578 1.78125 6.00391L4.71094 6.90039C4.89453 6.95898 5.04688 6.98242 5.16797 6.9707C5.29297 6.95508 5.41992 6.88867 5.54883 6.77148L11.6719 1.07031C11.7109 1.03516 11.75 1.01953 11.7891 1.02344C11.832 1.02344 11.8672 1.03711 11.8945 1.06445C11.9258 1.0918 11.9414 1.12695 11.9414 1.16992C11.9414 1.20898 11.9258 1.24609 11.8945 1.28125L6.20508 7.42188C6.0918 7.54688 6.02734 7.67188 6.01172 7.79688C5.99609 7.91797 6.01562 8.07422 6.07031 8.26562L6.9375 11.1309C7.02344 11.416 7.125 11.6504 7.24219 11.834C7.36328 12.0176 7.55469 12.1094 7.81641 12.1094Z'
                    fill='#55AAF9'
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <button className={"submit-btn"}>{t("saveChanges")}</button>
      </form>
    </section>
  );
};

export default Notifications;
