import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "../../../hooks/useWindowParams";
import { sidebarSelector } from "../../../store/slices/sidebar-slice";
import { useSelector } from "react-redux";
import makeRequest from "../../../api/makeRequest";
import Loader from "../../../shared/loader/loader";
import { useTranslation } from "react-i18next";

interface props {
  hideSidebarHandler: () => void;
}

const Support: React.FC<props> = ({ hideSidebarHandler }) => {
  const { width } = useWindowSize();
  const { sidebar } = useSelector(sidebarSelector);
  const { pathname } = useLocation();
  const [count, setCount] = useState<number>(0);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const hide = () => {
    if (width && width <= 768) {
      hideSidebarHandler();
    }
  };
  useEffect(() => {
    setConfirmLoading(true);
    makeRequest
      .get("api/tickets/messages/unread")
      .then(response => {
        if (response && response.data) {
          setCount(response.data.count);
        }
      })
      .catch(e => {
        if (e.response && e.response.data && e.response.data.status === 500) {
          navigate("/500");
        }
      })
      .finally(() => setConfirmLoading(false));
  }, [pathname]);
  const { t } = useTranslation();
  return (
    <Link onClick={hide} to={"/support"} className={"aside__item--wrapper"}>
      {confirmLoading && <Loader />}
      <div className={"aside__item--icon"}>
        <svg width='19' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <mask id='path-1-inside-1_2849_34275' fill='white'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M2.26831 1.56543C1.65091 1.56543 1.18101 2.11937 1.2817 2.72851L2.7693 11.7285C2.8491 12.2113 3.26657 12.5654 3.75591 12.5654H9.08945L13.5062 16.246C14.0671 16.7135 14.9096 16.2468 14.811 15.5234L14.4076 12.5654H15.9106C16.528 12.5654 16.9979 12.0115 16.8972 11.4024L15.4096 2.40235C15.3298 1.91956 14.9123 1.56543 14.423 1.56543H2.26831Z'
            />
          </mask>
          <path
            d='M1.2817 2.72851L0.295083 2.89158L1.2817 2.72851ZM2.7693 11.7285L1.78269 11.8916L1.78269 11.8916L2.7693 11.7285ZM9.08945 12.5654L9.72964 11.7972L9.4515 11.5654H9.08945V12.5654ZM13.5062 16.246L14.1464 15.4778L13.5062 16.246ZM14.811 15.5234L13.8202 15.6585V15.6585L14.811 15.5234ZM14.4076 12.5654V11.5654H13.262L13.4168 12.7005L14.4076 12.5654ZM16.8972 11.4024L15.9106 11.5654V11.5654L16.8972 11.4024ZM15.4096 2.40235L14.423 2.56543V2.56543L15.4096 2.40235ZM2.26831 2.56543L2.26831 2.56543V0.56543C1.03352 0.56543 0.0937173 1.67332 0.295083 2.89158L2.26831 2.56543ZM3.75591 11.5654L2.26831 2.56543L0.295083 2.89158L1.78269 11.8916L3.75591 11.5654ZM3.75591 11.5654H3.75591L1.78269 11.8916C1.94229 12.8572 2.77722 13.5654 3.75591 13.5654V11.5654ZM9.08945 11.5654H3.75591V13.5654H9.08945V11.5654ZM14.1464 15.4778L9.72964 11.7972L8.44927 13.3337L12.866 17.0143L14.1464 15.4778ZM13.8202 15.6585C13.8161 15.6286 13.8214 15.5814 13.8457 15.5357C13.8673 15.4955 13.8957 15.4707 13.9214 15.4565C13.9471 15.4423 13.9832 15.4313 14.0287 15.4344C14.0804 15.4379 14.1232 15.4585 14.1464 15.4778L12.866 17.0143C14.1281 18.066 16.0238 17.0161 15.8018 15.3883L13.8202 15.6585ZM13.4168 12.7005L13.8202 15.6585L15.8018 15.3883L15.3985 12.4303L13.4168 12.7005ZM15.9106 11.5654H14.4076V13.5654H15.9106V11.5654ZM15.9106 11.5654V11.5654V13.5654C17.1454 13.5654 18.0852 12.4575 17.8838 11.2393L15.9106 11.5654ZM14.423 2.56543L15.9106 11.5654L17.8838 11.2393L16.3962 2.23928L14.423 2.56543ZM14.423 2.56543V2.56543L16.3962 2.23928C16.2366 1.27369 15.4017 0.56543 14.423 0.56543V2.56543ZM2.26831 2.56543H14.423V0.56543H2.26831V2.56543Z'
            fill='#5ABE60'
            mask='url(#path-1-inside-1_2849_34275)'
          />
          <path
            d='M1.28043 2.72801C1.18009 2.11903 1.64993 1.56543 2.26712 1.56543H14.422C14.9115 1.56543 15.3291 1.91983 15.4087 2.40285L16.3973 8.40285C16.4976 9.01183 16.0278 9.56543 15.4106 9.56543H3.25576C2.76622 9.56543 2.34865 9.21103 2.26907 8.72801L1.28043 2.72801Z'
            fill='#5ABE60'
          />
        </svg>
      </div>
      <div className={`aside__item--info ${sidebar.isVisible ? "" : "aside__item--info-hidden"}`}>
        <h3 className={"aside__item--title"}>{t("support")}</h3>
      </div>
      {count > 0 && <div className={"aside__support--notification"}>{count}</div>}
    </Link>
  );
};

export default Support;
