import React from "react";
import "./subscribe-form.css";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface props {}

const SubscribeForm: React.FC<props> = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    resetField
  } = useForm();

  const onFinish = (e: any) => {};

  return (
    <>
      <form onSubmit={handleSubmit(onFinish)} className={"subscribe__form"}>
        <h2 className={"subscribe__title"}>{t("subscribe.title")}</h2>
        <div className={"subscribe__item"}>
          <label className={"subscribe__label"}>{t("subscribe.changeFrequency")}</label>
          <div className={"subscribe__radio-boxes"}>
            <label className={"subscribe__radio-box"}>
              {t("subscribe.onceInAWeek")}
              <input {...register("frequency", {})} className={"subscribe__radio"} type='radio' />
              <span className={"subscribe__radio-checkmark"} />
            </label>
            <label className={"subscribe__radio-box"}>
              {t("subscribe.onceInAMonth")}
              <input {...register("frequency", {})} className={"subscribe__radio"} type='radio' />
              <span className={"subscribe__radio-checkmark"} />
            </label>
            <label className={"subscribe__radio-box"}>
              {t("subscribe.onceInThreeMonth")}
              <input {...register("frequency", {})} className={"subscribe__radio"} type='radio' />
              <span className={"subscribe__radio-checkmark"} />
            </label>
          </div>
        </div>
        <div className={"subscribe__item"}>
          <label className={"subscribe__label"}>{t("subscribe.changeContent")}</label>
          <div className={"subscribe__radio-boxes"}>
            <label className={"subscribe__radio-box"}>
              {t("subscribe.updatesInfo")}
              <input {...register("content", {})} className={"subscribe__radio"} type='radio' />
              <span className={"subscribe__radio-checkmark"} />
            </label>
            <label className={"subscribe__radio-box"}>
              {t("subscribe.updatesInfo")}
              <input {...register("content", {})} className={"subscribe__radio"} type='radio' />
              <span className={"subscribe__radio-checkmark"} />
            </label>
            <label className={"subscribe__radio-box"}>
              {t("subscribe.updatesInfo")}
              <input {...register("content", {})} className={"subscribe__radio"} type='radio' />
              <span className={"subscribe__radio-checkmark"} />
            </label>
          </div>
        </div>
        <div className={"subscribe__item"}>
          <label className={"subscribe__label"}>{t("subscribe.stopMailList")}</label>
          <div className={"subscribe__radio-boxes"}>
            <label className={"subscribe__radio-box"}>
              {t("subscribe.forAWeek")}
              <input {...register("unsubscribe", {})} className={"subscribe__radio"} type='radio' />
              <span className={"subscribe__radio-checkmark"} />
            </label>
            <label className={"subscribe__radio-box"}>
              {t("subscribe.forAMonth")}
              <input {...register("unsubscribe", {})} className={"subscribe__radio"} type='radio' />
              <span className={"subscribe__radio-checkmark"} />
            </label>
            <label className={"subscribe__radio-box"}>
              {t("subscribe.forThreeMonth")}
              <input {...register("unsubscribe", {})} className={"subscribe__radio"} type='radio' />
              <span className={"subscribe__radio-checkmark"} />
            </label>
          </div>
        </div>
        <button className={"subscribe__btn"}>{t("subscribe.btn")}</button>
      </form>
      <p className={"subscribe__text"}>
        {t("subscribe.text")} <span>{t("subscribe.textSecond")}</span> {t("subscribe.textThird")}
        <span>{t("subscribe.textFourth")}</span>
      </p>
      <p className={"subscribe__follow"}>{t("subscribe.follow")}</p>
    </>
  );
};

export default SubscribeForm;
