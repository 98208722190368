import Upload from "./pages/upload/upload";
import React, { ReactElement } from "react";
import Homepage from "./pages/homepage/homepage";
import Members from "./pages/members/members";
import Team from "./pages/team/team";
import TeamSettings from "./pages/team-settings/team-settings";
import Settings from "./pages/settings/settings";
import Support from "./pages/support/support";
import TicketPage from "./pages/ticket-page/ticket-page";
import Privacy from "./pages/privacy/privacy";
import Help from "./pages/help/help";
import NotFound from "./pages/404/404";
import ServerError from "./pages/server-error/server-error";
import HasNoAccess from "./pages/hasNoAccess/hasNoAccess";
import Login from "./pages/login/login";
import Registration from "./pages/registration/registration";
import Success from "./pages/success/success";
import GetAccessCode from "./pages/get-access-code/get-access-code";
import Github from "./pages/get-access-code/github";
import OnBoarding from "./pages/help/components/onBoarding/onBoarding";
import ServerWorkPage from "./pages/server-work-page/server-work-page";
import NetworkError from "./pages/network-error/network-error";
import Unsubscribe from "./pages/unsubscribe/unsubscribe";

interface IRoute {
  path: string;
  component: ReactElement;
}

export const mainRoutes: IRoute[] = [
  {
    component: <Upload />,
    path: "/projects"
  },
  {
    component: <Homepage />,
    path: "/projects/:id"
  },
  {
    component: <Members />,
    path: "/teams/:teamId/members"
  },
  {
    component: <Team />,
    path: "/teams/:teamId/projects"
  },
  {
    component: <TeamSettings />,
    path: "/teams/:teamId/settings"
  },
  {
    component: <Homepage />,
    path: "/teams/:teamId/projects/:id"
  },
  {
    component: <Settings />,
    path: "/settings"
  },
  {
    component: <Support />,
    path: "/support"
  },
  {
    component: <TicketPage />,
    path: "/support/:id"
  },
  {
    component: <Help />,
    path: "/help"
  }
];

export const otherRoutes: IRoute[] = [
  {
    component: <NotFound />,
    path: "*"
  },
  {
    component: <ServerError />,
    path: "/500"
  },
  {
    component: <HasNoAccess />,
    path: "/403"
  },
  {
    component: <Login />,
    path: "/login"
  },
  {
    component: <Registration />,
    path: "/register"
  },
  {
    component: <OnBoarding />,
    path: "/onboarding"
  },
  {
    component: <Success />,
    path: "/success"
  },
  {
    component: <GetAccessCode />,
    path: "/google-oauth"
  },
  {
    component: <Github />,
    path: "/github-oauth"
  },
  {
    component: <Privacy />,
    path: "/privacy-policy"
  },
  {
    component: <ServerWorkPage />,
    path: "/maintenance"
  },
  {
    component: <NetworkError />,
    path: "/network-error"
  },
  {
    component: <Unsubscribe />,
    path: "/unsubscribe"
  }
];
