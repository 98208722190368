import React from "react";
import { IMembers } from "../../../../../interfaces/members/members";
import { membersSelector } from "../../../../../store/slices/members-slice";
import { useSelector } from "react-redux";
import Loader from "../../../../../shared/loader/loader";
import { userSelector } from "../../../../../store/slices/user-slice";
import { memberRoles } from "../../../../../constants/user-roles";
import MemberAddForm from "./member-add-form/member-add-form";
import { useParams } from "react-router-dom";
import { IPriorityList, priorityList } from "../../../../../constants/permissions";
import { useTranslation } from "react-i18next";
import { teamSelector } from "../../../../../store/slices/team-slice";

interface props {
  member: IMembers;
}

const MembersItem: React.FC<props> = ({ member }) => {
  const { members } = useSelector(membersSelector);
  const { teamId } = useParams();
  const { team } = useSelector(teamSelector);
  const { t } = useTranslation();
  const { user } = useSelector(userSelector);
  const role = member?.roleList?.find(item => item.team.id.toString() === teamId)?.role;
  const userRole = user.entity.role.find(item => item.teamID.toString() === teamId)?.role;
  const priority = member?.roleList?.find(item => item.team.id.toString() === teamId)?.priority;
  const permission = user.entity.role?.find(item => item.teamID.toString() === teamId)?.permissions;

  return (
    <div className={`member-add-form dark ${role === memberRoles.viewer ? "isUserDark" : ""}`}>
      {members.confirmLoading && <Loader />}
      <div className={"member-add-form__info"}>
        <p className={"member-add-form__title"}>
          {member.username}{" "}
          {member.id === user.entity.id && <span className={"member-add-form__me"}>{t("itsYou")}</span>}
        </p>
        <p className={"member-item__email"}>{member.email}</p>
      </div>
      <div className={"member-add-form__permissions"}>
        <div className={"column-project__teams"}>
          <p className={"member-add-form__role"}>
            {member.id === team.entity.owner.id ? t("owner") : role ? t(role) : ""}
          </p>
        </div>
        {member.id === user.entity.id || team.entity.owner.id === member.id ? null : permission &&
          permission.canAddParticipants &&
          priority &&
          priority >= priorityList[`${userRole as keyof IPriorityList}`] ? (
          <MemberAddForm member={member} edit={true} />
        ) : null}
      </div>
    </div>
  );
};

export default MembersItem;
