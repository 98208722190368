import React from "react";
import { IProgress } from "../upload-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearProjectProgress, progressSelector } from "../../../../../store/slices/progress-slice";
import { useTranslation } from "react-i18next";

interface props {
  progressEntity: IProgress;
  result: any;
}

const ProgressResult: React.FC<props> = ({ progressEntity, result }) => {
  const {
    id,
    total,
    isCanceled,
    speed,
    loaded,
    projectName,
    projectType,
    percentage,
    leftTime,
    projectId,
    isError,
    messageError,
    abort
  } = progressEntity;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const newProjectName = projectName;
  const { teamId } = useParams();

  const chooseText = () => {
    if (isCanceled) {
      return t("cancel");
    } else if (isError) {
      return messageError;
    } else if (total === loaded) {
      return t("done");
    } else {
      return `${speed ? speed : ""}MB/s`;
    }
  };
  const navigate = useNavigate();
  const onRedirectToProject = () => {
    if (projectId) {
      if (teamId) {
        navigate(`/teams/${teamId}/projects/${projectId}`);
      } else {
        navigate(`/projects/${projectId}`);
      }
    }
  };
  const removeProgress = () => {
    dispatch(clearProjectProgress(id));
  };
  return (
    <div className={"upload-project__loader"}>
      <div className={"upload-project__info"}>
        <div className={"upload-project__name"}>{newProjectName ? newProjectName.slice(0, 25) : null}</div>
        <div className={"upload-project__type"}>{projectType ? projectType : null}</div>
      </div>
      {loaded !== total ? (
        <div className={"upload-project__progress"}>
          <p className={"upload-project__done"}>
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M8 15C6.61553 15 5.26216 14.5895 4.11101 13.8203C2.95987 13.0511 2.06266 11.9579 1.53285 10.6788C1.00303 9.3997 0.86441 7.99224 1.13451 6.63437C1.4046 5.2765 2.07129 4.02922 3.05026 3.05026C4.02922 2.07129 5.2765 1.4046 6.63437 1.13451C7.99224 0.86441 9.3997 1.00303 10.6788 1.53285C11.9579 2.06266 13.0511 2.95987 13.8203 4.11101C14.5895 5.26216 15 6.61553 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 2C6.81332 2 5.65328 2.3519 4.66658 3.01119C3.67989 3.67047 2.91085 4.60755 2.45673 5.7039C2.0026 6.80026 1.88378 8.00666 2.11529 9.17054C2.3468 10.3344 2.91825 11.4035 3.75736 12.2426C4.59648 13.0818 5.66558 13.6532 6.82946 13.8847C7.99335 14.1162 9.19975 13.9974 10.2961 13.5433C11.3925 13.0892 12.3295 12.3201 12.9888 11.3334C13.6481 10.3467 14 9.18669 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2V2Z'
                fill='#808080'
              />
              <path d='M10.295 11L7.5 8.205V3.5H8.5V7.79L11 10.295L10.295 11Z' fill='#808080' />
            </svg>
            {leftTime}
          </p>
          <p className={"upload-project__done"}>
            {total === loaded && (
              <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M4.94727 11.5664C5.25716 11.5664 5.4987 11.4388 5.67188 11.1836L11.7627 1.63379C11.8265 1.53353 11.8721 1.44238 11.8994 1.36035C11.9268 1.27376 11.9404 1.18945 11.9404 1.10742C11.9404 0.906901 11.8766 0.745117 11.749 0.62207C11.626 0.494466 11.4642 0.430664 11.2637 0.430664C11.1178 0.430664 10.9971 0.460286 10.9014 0.519531C10.8102 0.574219 10.7191 0.669922 10.6279 0.806641L4.91992 9.88477L1.97363 6.06348C1.80501 5.83561 1.59993 5.72168 1.3584 5.72168C1.14876 5.72168 0.977865 5.78776 0.845703 5.91992C0.713542 6.04753 0.647461 6.21159 0.647461 6.41211C0.647461 6.50326 0.663411 6.5944 0.695312 6.68555C0.731771 6.77214 0.788737 6.861 0.866211 6.95215L4.20898 11.1768C4.41862 11.4365 4.66471 11.5664 4.94727 11.5664Z'
                  fill='#808080'
                />
              </svg>
            )}
            {chooseText()}
          </p>
        </div>
      ) : null}
      <div className={"upload-project__result"}>
        {loaded !== total && (
          <>
            <div className={"upload-project__percentage"}>{percentage ? `${percentage}%` : null}</div>
            <p>{`(${loaded}mb/${total}mb)`}</p>
            <div className={"intersection__item--percentage upload-project__width"}>
              <div
                className={"intersection__item--percentage-info"}
                style={{
                  width: `${percentage}%`,
                  backgroundColor: "#23D86B"
                }}
              />
            </div>
          </>
        )}
        <button
          onClick={result.isError ? () => dispatch(clearProjectProgress(id)) : projectId ? onRedirectToProject : abort}
          className={`upload-project__stop ${total === loaded && projectId ? "upload-project__open" : ""}`}
        >
          {result.isError ? t("reset") : total === loaded && projectId ? t("open") : t("cancel")}
        </button>
        {total === loaded && (
          <svg
            onClick={removeProgress}
            className={"upload-project__close"}
            width='13'
            height='13'
            viewBox='0 0 13 13'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1.0625 11.8203C1.13542 11.8984 1.22396 11.9479 1.32812 11.9688C1.43229 11.9948 1.53385 11.9948 1.63281 11.9688C1.73177 11.9479 1.82292 11.8984 1.90625 11.8203L6.69531 7.02344L11.4922 11.8203C11.6068 11.9349 11.7474 11.9922 11.9141 11.9922C12.0807 11.9974 12.2214 11.9401 12.3359 11.8203C12.4505 11.7005 12.5078 11.5573 12.5078 11.3906C12.5078 11.224 12.4505 11.0859 12.3359 10.9766L7.53906 6.17969L12.3359 1.38281C12.4505 1.27344 12.5078 1.13542 12.5078 0.96875C12.513 0.796875 12.4557 0.653646 12.3359 0.539062C12.2214 0.424479 12.0807 0.367188 11.9141 0.367188C11.7474 0.367188 11.6068 0.424479 11.4922 0.539062L6.69531 5.33594L1.90625 0.539062C1.82292 0.466146 1.73177 0.416667 1.63281 0.390625C1.53385 0.364583 1.43229 0.364583 1.32812 0.390625C1.22396 0.411458 1.13542 0.460938 1.0625 0.539062C0.984375 0.617188 0.932292 0.708333 0.90625 0.8125C0.880208 0.911458 0.880208 1.01302 0.90625 1.11719C0.932292 1.22135 0.984375 1.3099 1.0625 1.38281L5.85156 6.17969L1.0625 10.9766C0.984375 11.0495 0.929688 11.138 0.898438 11.2422C0.872396 11.3464 0.872396 11.4505 0.898438 11.5547C0.924479 11.6589 0.979167 11.7474 1.0625 11.8203Z'
              fill='#A9A9A8'
            />
          </svg>
        )}
      </div>
    </div>
  );
};

export default ProgressResult;
