import React from "react";
import "./success.css";
import SuccessModal from "./components/success-modal";

interface props {}

const Success: React.FC<props> = () => {
  return (
    <section className={"login"}>
      <div className={"login-top"} />
      <SuccessModal />
    </section>
  );
};

export default Success;
