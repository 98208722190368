import React from "react";
import LeaveFromTeamModal from "./leave-from-team-modal";
import { useTranslation } from "react-i18next";

interface props {}

const LeaveFromTeam: React.FC<props> = () => {
  const { t } = useTranslation();
  return (
    <div className={"intersection-range"}>
      <h4 className={"intersection-range__title"}>{t("leaveTeam")}</h4>
      <div className={"intersection-range__inputs"}>
        <p className={"intersection-range__text"}>{t("leaveTeamText")}</p>
      </div>
      <div className={"intersection-range__btn leave-btn__wrapper"}>
        <LeaveFromTeamModal />
      </div>
    </div>
  );
};

export default LeaveFromTeam;
